import React from 'react';
import { Block, Row, Card, CardContent, Button, Chip, Col, Preloader } from 'framework7-react';
import { connect } from 'react-redux';
//import RouterModule from 'framework7/modules/router/router';
import { RaUI, RaApiAuth, RaApiContacts, RaStorage, AVAILABILITY } from 'pikkart-remote-assistance';
/*import RaUI from '../ra-lib/ui/ui';
import RaApiAuth from '../ra-lib/api/auth';
import RaApiContacts from '../ra-lib/api/contacts';
import RaStorage from '../ra-lib/storage';
import { AVAILABILITY } from '../ra-lib/api/contacts';*/

class Settings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userOnline: true,
    };
  }

  render() {

    if (!RaApiAuth.isAuthenticated()) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    let groups = null;

    if (RaStorage.getProfile().Groups && RaStorage.getProfile().Groups.length > 0) {
      groups = RaStorage.getProfile().Groups.map((item, index) => {
        return <Chip outline key={item.Name} text={item.Name} />
      });
    }

    const languages = { EN: "English", IT: "Italiano", ES: "Espanol", FR: "Français" };
    let lang = null
    if (RaStorage.getProfile().SpokenLanguagesRegion && RaStorage.getProfile().SpokenLanguagesRegion.length > 0) {
      lang = RaStorage.getProfile().SpokenLanguagesRegion.map((item, index) => {
        return <Chip outline key={item} text={languages[item]} />
      });
    }


    const userOnline = this.state.userOnline;
    let buttonsRow;
    if (userOnline) {
      buttonsRow = (
        <Row tag="p">
          <Col tag="span">
            <Button raised fill onClick={(e) => this.switchUserStatus(AVAILABILITY.ONLINE)}>Available</Button>
          </Col>
          <Col tag="span">
            <Button raised onClick={(e) => this.switchUserStatus(AVAILABILITY.OFFLINE)}>Unavailable</Button>
          </Col>
        </Row>
      );
    } else {
      buttonsRow = (
        <Row tag="p">
          <Col tag="span">
            <Button raised onClick={(e) => this.switchUserStatus(AVAILABILITY.ONLINE)}>Available</Button>
          </Col>
          <Col tag="span">
            <Button raised fill onClick={(e) => this.switchUserStatus(AVAILABILITY.OFFLINE)}>Unavailable</Button>
          </Col>
        </Row>
      );
    }



    return (
      <div>
        <Block textAlign="center">
          <Row>
            <Col width="30" style={{ textAlign: 'right' }}>
              <img src={RaUI.getAvatar(RaStorage.getProfile().Name)} alt="avatar" width="48" style={{ borderRadius: "50%" }} />
            </Col>
            <Col width="70">
              <strong style={{ display: "block", fontSize: '120%' }}>{RaStorage.getProfile().Name ? RaStorage.getProfile().Name : "N.A."}</strong>
              <div style={{ display: RaStorage.getProfile().Company ? "block" : "none" }}>{RaStorage.getProfile().Company ? RaStorage.getProfile().Company.Name : null}</div>
              <div style={{ display: RaStorage.getProfile().CompanyRole ? "block" : "none" }}>{RaStorage.getProfile().CompanyRole}</div>
              <div style={{ display: RaStorage.getProfile().Email ? "block" : "none" }}>{RaStorage.getProfile().Email}</div>
            </Col>
          </Row>
        </Block>
        <Card noShadow outline>
          <CardContent>
            <Block strong>
              <p className="date">Status</p>
              <Block tag="p" style={{ textAlign: "center" }}>
                {buttonsRow}
              </Block>
            </Block>
          </CardContent>
        </Card>
        <Card noShadow outline style={{ display: RaStorage.getProfile().Groups != null && RaStorage.getProfile().Groups.length > 0 ? "block" : "none" }}>
          <CardContent>
            <Block strong>
              <p className="date">Groups</p>
              {groups}
            </Block>
          </CardContent>
        </Card>
        <Card noShadow outline style={{ display: RaStorage.getProfile().SpokenLanguagesRegion != null && RaStorage.getProfile().SpokenLanguagesRegion.length > 0 ? "none" : "none" }}>
          <CardContent>
            <Block strong>
              <p className="date">Languages</p>
              {lang}
            </Block>
          </CardContent>
        </Card>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={(e) => {
            this.$f7.dialog.confirm('Do you want to logout?', 'Logout', () => {
              RaApiAuth.doLogout();
            })
          }}>Logout</Button>
        </div>
      </div>

    );
  }

  componentDidMount = () => {
    if (RaApiAuth.isAuthenticated()) {

      switch (RaStorage.getProfile().Availability) {
        case AVAILABILITY.ONLINE:
          this.setState({ userOnline: true });
          break;
        case AVAILABILITY.OFFLINE:
          this.setState({ userOnline: false });
          break;
        default:
          break;
      }

    }
  }

  switchUserStatus = (availability) => {
    if ((this.state.userOnline && availability === AVAILABILITY.OFFLINE) ||
      (!this.state.userOnline && availability === AVAILABILITY.ONLINE)) {
      RaApiContacts.updateAvailability(availability)
        .then(function (response) {

          if (response.result.success === true) {
            successFn(response.data);
          } else {
            errorFn(response.result.message);
          }
        })
        .catch(function (response) {
          errorFn(response.message);
        });
    }

    const errorFn = (errorMsg) => {
      console.log(errorMsg);
    }

    const successFn = (profile) => {

      switch (profile.Availability) {
        case AVAILABILITY.ONLINE:
          this.setState({ userOnline: true });
          break;
        case AVAILABILITY.OFFLINE:
          this.setState({ userOnline: false });
          break;
        default:
          break;
      }
    }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
    profile: state.remoteAssistance.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //setToken: (token) => dispatch(actions.setToken(token)),
  }
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Settings);