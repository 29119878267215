import React from 'react';
import { Page, Icon, Toolbar, Fab, Link, Tabs, Tab, Block, Navbar, Popup, NavRight, Badge } from 'framework7-react';
import { connect } from 'react-redux';
import CurrentCalls from './current-calls';
import CallsHistory from './calls-history';
import Documents from './documents';
import Settings from './settings';
import Login from './login';
// import ContactsList from './contacts-list';
import CallAnnotationsView from './call-annotations-view';
import CallChatView from './call-chat-view';
import CallChat from './call-chat';
import DocumentsFilter from './documents-filter';
//import VideoMain from './videomain';
import VideoCallMain from './call/video-call-home';
import CallMain from './callmain'
import * as actions from '../redux/actions';
import { initializedFirebaseApp } from "../init-fcm";
import Spinner from '../components/Spinner';

import phoneCallingAudio from '../assets/mp3/phone-calling.mp3';
import phoneBusyAudio from '../assets/mp3/phone-busy.mp3';
import phoneRingAudio from '../assets/mp3/phone-ring.mp3';
import phoneUnavailableAudio from '../assets/mp3/phone-unavailable.mp3';

import { RaReduxActions, RaStorage, RaApiAuth, RaApiContacts, RaApiCalls, RaApiCallsMessages, RaNotifications, RaCordova, RaApiCallsPics, RaCordovaIntent, RaCordovaPermissions } from 'pikkart-remote-assistance';

/*import RaReduxActions from '../ra-lib/redux/actions';
import RaStorage from '../ra-lib/storage';
import RaApiAuth from '../ra-lib/api/auth';
import { RaApiContacts, AVAILABILITY } from '../ra-lib/api/contacts';
import RaApiCalls from '../ra-lib/api/calls/calls';
import RaApiCallsMessages from '../ra-lib/api/calls/message'
import RaNotifications from '../ra-lib/notifications';
import RaCordova from '../ra-lib/cordova';
import RaApiCallsPics from '../ra-lib/api/calls/pics/pics';*/

const PAGETITLE_CALLINPROGRESS = "Topjet";
const PAGETITLE_RECENTS = "Recents";
const PAGETITLE_DOCUMENTS = "Documents";
const PAGETITLE_SETTINGS = "Profile";

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: "main-1",
      spinner: false,
      newInstantCallContact: null,
      clickedCallId: null,
      // activeCall: RaApiCalls.getActiveCall(),
      activeCallDuration: null,
      pageTitle: PAGETITLE_CALLINPROGRESS,
      navBarRightSlot: null,
      navBarLeftSlot: null,
      mainpopupshow: false,
      mainpopuptitle: null,
      mainpopupcontent: null,
      incomingCall: RaApiCalls.getIncomingCall(),
      showcalling: false,
      orientation: null,
    };

    RaApiCalls.onTargetConnected = () => {
      if (this.state.showcalling === true) {
        this.setState({ showcalling: false });
      }
      this.props.setMustUpdateTargets(true);
    }

    RaApiCalls.onParticipantDisconnected = () => {
      console.log('home onParticipantDisconnected');
      this.props.setMustUpdateTargets(true);
    }

    RaApiCalls.onActiveCallClosed = () => {
      console.log('active call closed');
      if (this.state.showcalling === true)
        this.setState({ showcalling: false });
      if (this.state.page.startsWith('chatinterface'))
        this.nextPageHandler('main-1');
      this.setState({ newInstantCallContact: null });

      this.props.setMustUpdateHome(true);
    }

    RaApiCalls.onCallDeclinedByTarget = () => {
      console.log('onCallDeclinedByTarget');
      if (this.state.showcalling === true)
        this.setState({ showcalling: false });
      /*if (this.state.showcalling == true
        && this.state.page.startsWith('chatinterface'))
        this.nextPageHandler('main-1');*/
    }

    var _self = this;
    this.$f7.on('resize', function (data) {
      console.log('resize', data);
      _self.checkOrientation(this.width, this.height);
    });

    if (RaCordova.isCordova()) {
      console.log('isCordova', true);
      document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
    }
  }

  onDeviceReady = () => {
    console.log('onDeviceReady');
    document.addEventListener("backbutton", this.onBackKeyDown.bind(this), false);

    RaCordovaIntent.listenForCordovaIntent();
  }

  onBackKeyDown = () => {
    console.log('onBackKeyDown');

    const self = this;

    if (this.state.page) {

      if (this.state.showcalling === true)
        this.setState({ showcalling: false });
      else if (this.state.mainpopupshow === true) {
        this.setState({ mainpopupshow: false });
      }
      else if (this.state.page.startsWith('chatinterface')) {
        this.$f7.dialog.confirm("Are you sure you want to close the call?", "Exit call",
          function () {
            self.closeActiveCall();
          },
          function () { }
        );
      }
      else if (this.state.page !== "main-1") {
        this.nextPageHandler("main-1");
      }
      else {
        this.$f7.dialog.confirm("Are you sure you want to close the app?", "Exit app",
          function () {
            navigator.app.exitApp();
          },
          function () { }
        );
      }
    }
  }

  checkOrientation = (width, height) => {
    let currentOrientation = this.ORIENTATION_PORTRAIT;
    if (width > height)
      currentOrientation = this.ORIENTATION_LANDSCAPE;

    if (this.state.orientation !== currentOrientation)
      this.setState({ orientation: currentOrientation });
  }

  ORIENTATION_LANDSCAPE = 'landscape';
  ORIENTATION_PORTRAIT = 'portrait';


  mainPopupHandler = (title, content) => {
    this.setState({
      mainpopupcontent: content,
      mainpopuptitle: title,
      mainpopupshow: true
    });
  }

  currentCallsHandler = (calls) => {
    if (calls.length === 1) {
      const call = calls[0];
      const closedCallsIds = RaStorage.getClosedCallsIds();
      const isCallInClosedCalls = closedCallsIds.includes(call.UniqueId);
      if (call.IsIstantCall && !isCallInClosedCalls) {
        RaApiCalls.setIncomingCall(call);
      }
    }
  }

  render() {

    if (this.props.isCordovaReady === false) {
      return <Spinner />;
    }

    if (!RaApiAuth.isAuthenticated()) {
      return <Login />;
    }

    if (this.state.spinner === true) {
      return <Spinner />;
    }

    switch (this.state.page) {

      case "annotationView":
        return <CallAnnotationsView callId={this.state.clickedCallId} callerId={this.state.clickedCallerId} nextPage={this.nextPageHandler} live={false} />;

      case "chatview":
        return <CallChatView callId={this.state.clickedCallId} nextPage={this.nextPageHandler} />;
      /*
            case "chatinterface-3":
              return <CallChatView callId={this.props.activeCall.Call.UniqueId} live={true} nextPage={(e) => { this.setState({ page: "chatinterface-2" }); }} />;
      */
      case "documentsfilter":
        return <DocumentsFilter nextPage={this.nextPageHandler} />;
      /*
            case "liveAnnotationView":
              return <CallAnnotationsView live={true} nextPage={(e) => { this.setState({ page: "chatinterface-1" }); }} />;
      */
      case "chatinterface-1":
      case "chatinterface-2":
      case "chatinterface-3":
        return this.chatInterface();
      default:
        return this.mainInterface();
    }
  }

  fetchNewInstantCall = (contact) => {

    this.setState({ spinner: true, newInstantCallContact: contact });

    const errorFn = (errorMsg, errorCode) => {

      let callContact = this.state.newInstantCallContact;

      this.setState({
        calls: null,
        spinner: false,
        errormessage: errorMsg,
        newInstantCallContact: null
      });

      let audioPlayer = document.getElementById("audio-player");
      if (audioPlayer) {
        /*   audioPlayer.src = '/mp3/phone-busy.mp3';
           audioPlayer.loop = true;
           audioPlayer.duration = 4;
           audioPlayer.play();  */
      }
      else
        console.log('audio-player is null');


      if (errorCode === 404)
        errorMsg = callContact.Name + ' is offline at the moment.';
      else if (errorCode === 409)
        errorMsg = callContact.Name + ' is occupied at the moment.';

      if (errorMsg && errorMsg.length > 100)
        errorMsg = errorMsg.substring(0, 100) + '...';

      this.$f7.dialog.alert(errorMsg, 'Unable to call', function () {
        //if (audioPlayer)
        //audioPlayer.stop();
      });
    }

    const successFn = (call) => {
      const self = this;
      //si da il tempo necessario per chiudere il popup dei contatti, senza che il cambio pagine interferisca, generanto scatti
      setTimeout(function () {
        self.setState({ showcalling: true, page: "chatinterface-2", spinner: false });
      }, 750);
    }

    let productCode = RaCordovaIntent.getProductCode();
    if (!productCode)
      productCode = 'xxxxxxxxxx';

    RaApiCalls.startNewCall(this.state.newInstantCallContact.Name, [this.state.newInstantCallContact], productCode)
      .then(function (response) {

        if (response.result.success === true) {

          successFn(response.data);
        }
        else {

          errorFn(response.result.message, response.result.code);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });

  }

  fetchProfile = () => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (profile) => {

    }

    RaApiAuth.getProfile()
      .then(function (response) {
        //handle success
        //console.log(response);

        if (response.result.success === true) {
          // handle success
          //response.data contiene il token
          successFn(response.data);
        } else {
          // handle error
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        //handle error
        errorFn(response.message);
      });

  };

  componentDidMount() {

    /*********************************
     * GESTIONE SESSIONE
     ********************************/
    RaApiContacts.startOnlineSession();


    /*********************************
     * GESTIONE USCITA DALLA PAGINA
     ********************************/
    window.addEventListener('beforeunload', (event) => {
      if (RaApiCalls.getActiveCall() != null) {
        event.returnValue = 'There is pending work. Sure you want to leave?';
      }

      window.focus();
    });


    /****************************
     * GESTIONE NOTIFICHE
     ***************************/
    if (!RaCordova.isCordova()) {
      RaNotifications.init_web_notifications(initializedFirebaseApp, 'BL7Zf9xd07hISZm12JViG-BH6ABk5Hw71rUCmaG0V3wMh4TasNd8sLj3MLANEiZcWFbw3_LXnlfe-JwDu30dkFk');
      if (RaApiAuth.isAuthenticated()) {
        RaNotifications.checkServiceRegistration().catch(function (errorMessage) {
          //handle error
          console.log('checkServiceRegistration error', errorMessage);
        });
      }
    }

    /****************************
     * FINE GESTIONE NOTIFICHE
     ***************************/

    const token = RaStorage.getToken();
    if (token) {
      this.props.setToken(token);
      this.fetchProfile();
    }
    /*
        setInterval(() => {
          if (RaApiCalls.getActiveCall() != null && RaApiCalls.getActiveCall().StartedAt != null && document.getElementById('spCallDuration'))
            this.$$('#tab2 > .tabbar-label')[0].innerText = RaUI.getCallDurationTime(RaApiCalls.getActiveCall().StartedAt);
    
        }, 1000);
    */

    RaApiCalls.setPhoneCallingAudio(phoneCallingAudio);
    RaApiCalls.setPhoneBusyAudio(phoneBusyAudio);
    RaApiCalls.setPhoneRingAudio(phoneRingAudio);
    RaApiCalls.setPhoneUnavailableAudio(phoneUnavailableAudio);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    /*if (prevState.page.toLowerCase().startsWith("main") && this.state.page.toLowerCase().startsWith("chatinterface")) {
      //console.log("setup connessione");

      //this.props.connectToComunicationProvider(div, this.props.setDataTrack, this.props.setVideoTrack);
    }*/

    if (this.props.dataTrack !== null && this.props.dataTrack !== prevProps.dataTrack) {
      if (this.state.page !== 'chatinterface-1') {

        this.$$('#call-page > .page-content').css('overflow', 'hidden');
      }
      else
        this.$$('#call-page > .page-content').css('overflow', 'auto');
    }

  };

  newInstantCallHandler = (contact) => {
    if (RaCordova.isCordova()) {
      const self = this;
      RaCordovaPermissions.askCallPermissions()
        .then(function (response) {
          console.log('request permissions success ' + JSON.stringify(response));
          self.fetchNewInstantCall(contact);
        })
        .catch(function (response) {
          console.log('request permissions failed ' + JSON.stringify(response));

          self.$f7.dialog.alert('You need to grant permissions in order to start the call', "Permessions", function () {

          });
        });
    }
    else {
      this.fetchNewInstantCall(contact);
    }
  }

  getCurrentPageTitle = () => {
    if (this.state.page.endsWith("1"))
      return PAGETITLE_CALLINPROGRESS;
    else if (this.state.page.endsWith("2"))
      return PAGETITLE_RECENTS;
    else if (this.state.page.endsWith("3"))
      return PAGETITLE_DOCUMENTS;
    else if (this.state.page.endsWith("4"))
      return PAGETITLE_SETTINGS;
  }

  setNavBarHandler = (title, leftSlotContent, rightSlotContent) => {
    this.setState({ pageTitle: title != null ? title : this.getCurrentPageTitle(), navBarLeftSlot: leftSlotContent, navBarRightSlot: rightSlotContent });
  }

  chatPageHandler = (page) => {

    if (this.state.page.endsWith("1"))
      RaApiCallsPics.clearNotSeenPhotos();

    this.setState({ page: page });
  }

  nextPageHandler = (page) => {

    this.setState({ page: page });
    if (this.state.page.endsWith("1")) {
      this.setState({ pageTitle: this.getCurrentPageTitle(), navBarRightSlot: null, navBarLeftSlot: null, showcalling: false });
    }
    else if (this.state.page.endsWith("2")) {
      this.setState({ pageTitle: this.getCurrentPageTitle(), navBarRightSlot: null, navBarLeftSlot: null, showcalling: false })
    }
    else if (this.state.page.endsWith("3")) {
      this.setState({
        pageTitle: this.getCurrentPageTitle(),
        navBarRightSlot: null,//<div slot="right" onClick={(e) => { this.nextPageHandler("documentsfilter"); }}><Icon ios="f7:slider_horizontal_3" aurora="f7:slider_horizontal_3" md="material:tune"></Icon></div>,
        navBarLeftSlot: null, showcalling: false
      })

    }
    else if (this.state.page.endsWith("4")) {
      this.setState({ pageTitle: this.getCurrentPageTitle(), navBarRightSlot: null, navBarLeftSlot: null, showcalling: false })
    }
  }

  chatCallIdHandler = (callId, callerId) => {
    this.setState({ clickedCallId: callId, clickedCallerId: callerId });
  }

  showToastBottom(text) {
    const self = this;
    // Create toast
    if (!self.toastBottom) {
      self.toastBottom = self.$f7.toast.create({
        text: text,
        closeTimeout: 2000,
      });
    }
    // Open it
    self.toastBottom.open();
  }

  onAcceptedCall = () => {
    const self = this;

    if (RaCordova.isCordova()) {
      RaCordovaPermissions.askMicPermission()
        .then(function (response) {
          console.log('request permissions success ' + JSON.stringify(response));
          self.acceptIncomingCall();
        })
        .catch(function (response) {
          console.log('request permissions failed ' + JSON.stringify(response));
          self.$f7.dialog.alert('You need to grant permissions in order to start the call', "Permessions", function () {

          });
        });
    }
    else {
      this.acceptIncomingCall();
    }
  }

  acceptIncomingCall = () => {
    const self = this;
    RaApiCalls.acceptIncomingCall()
      .then(self.goToCallPage)
      .catch(function (errorMsg) {
        self.setState({
          errormessage: errorMsg,
        });

        self.showToastBottom(errorMsg);
        self.onDeclinedCall();
      });
  }

  onDeclinedCall = () => {
    const self = this;
    RaApiCalls.declineIncomingCall()
      .catch(function (errorMsg) {
        self.setState({
          errormessage: errorMsg,
        });
      });
  }

  closeActiveCall = () => {
    RaApiCalls.closeActiveCall();
  }

  /*
  fetchJoinCall = (call) => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          calls: null,
          errormessage: errorMsg,
        });
      }
    }

    RaApiCallsParticipants.startToPartecipate(call)
      .then(function (response) {
        console.log(response);

        if (response.result.success === true) {
          this.goToCallPage();
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });
  }*/

  wait = function (time) {
    return new Promise(function (resolve) {
      setTimeout(resolve, time)
    })
  }

  goToCallPage = () => {

    const self = this;
    self.wait(1000)
      .then(function () {
        self.nextPageHandler('chatinterface-2');
      });
  }

  callingState = () => {
    return this.props.callingState === true && !this.props.comunicationProviderError;
  }

  chatInterface = () => { // <Page className="page-home">

    const unreadMessagesCount = this.props.unreadMessages.length;
    let unreadMessagesBadge;
    if (unreadMessagesCount > 0) {
      unreadMessagesBadge = (<Badge color="red">{this.props.unreadMessages.length}</Badge>)
    }

    const notSeenPhotosCount = this.props.notSeenPhotos.length;
    let notSeenPhotosBadge;
    if (notSeenPhotosCount > 0) {
      notSeenPhotosBadge = (<Badge color="red">{this.props.notSeenPhotos.length}</Badge>)
    }

    let t1 = <Link onClick={() => {
      this.chatPageHandler("chatinterface-1");
      //RaApiCallsPics.clearNotSeenPhotos();
      //RaApiCalls.askCallerVideoState();
      //RaApiCalls.askAnnotationSelection();
    }} text={this.$f7.device.desktop ? "LIVE CAMERA" : ""} tabLink="#tab-1" iconIos="f7:videocam" iconAurora="f7:videocam" iconMd="material:videocam">
      {notSeenPhotosBadge}
    </Link>;
    let t2 = <Link onClick={() => {
      this.chatPageHandler("chatinterface-2");
    }} tabLink="#tab-2" text={this.$f7.device.desktop ? "CALL COMMANDS" : ""} id="tab2" iconIos="f7:phone" iconAurora="f7:phone" iconMd="material:phone_in_talk"></Link>;
    let t3 = <Link onClick={() => {
      this.chatPageHandler("chatinterface-3");
      RaApiCallsMessages.clearUnreadMessages();
    }} text={this.$f7.device.desktop ? "MESSAGES" : ""} tabLink="#tab-3" iconIos="f7:chat_bubble" iconAurora="f7:chat_bubble" iconMd="material:chat_bubble">
      {unreadMessagesBadge}
    </Link>;

    if (this.state.page.endsWith("1")) {
      t1 = <Link text={this.$f7.device.desktop ? "LIVE CAMERA" : ""} tabLinkActive tabLink="#tab-1" iconIos="f7:videocam" iconAurora="f7:videocam" iconMd="material:videocam"></Link>;
    }

    if (this.state.page.endsWith("2")) {
      t2 = <Link text={this.$f7.device.desktop ? "CALL COMMANDS" : ""} tabLinkActive id="tab2" tabLink="#tab-2" iconIos="f7:phone" iconAurora="f7:phone" iconMd="material:phone_in_talk"></Link>;
    }

    if (this.state.page.endsWith("3")) {
      t3 = <Link text={this.$f7.device.desktop ? "MESSAGES" : ""} tabLinkActive tabLink="#tab-3" iconIos="f7:chat_bubble" iconAurora="f7:chat_bubble" iconMd="material:chat_bubble"></Link>;
    }

    let ta1 = <Tab id="tab-1" className="page-content" style={{ backgroundColor: '#000', overflow: 'hidden' }}><VideoCallMain callId={RaApiCalls.getActiveCall() ? RaApiCalls.getActiveCall().UniqueId : null} isCallingState={this.callingState}></VideoCallMain>({/*<VideoMain nextPage={this.nextPageHandler} />*/})</Tab>;
    let ta2 = <Tab id="tab-2" className="page-content callTab"><CallMain nextPage={this.nextPageHandler} invitedContact={this.state.newInstantCallContact} /></Tab>;
    let ta3 = <Tab id="tab-3" className="page-content callTab"><CallChat nextPage={this.nextPageHandler} callId={RaApiCalls.getActiveCall() != null ? RaApiCalls.getActiveCall().UniqueId : null} live={true} productCode={this.props.productCode} /></Tab>;

    if (this.state.page.endsWith("1")) {
      ta1 = <Tab id="tab-1" className="page-content" style={{ backgroundColor: '#000', paddingBottom: 0 }} tabActive><VideoCallMain callId={RaApiCalls.getActiveCall() ? RaApiCalls.getActiveCall().UniqueId : null} isCallingState={this.callingState}></VideoCallMain>({/*<VideoMain nextPage={this.nextPageHandler} />*/})</Tab>;
    }

    if (this.state.page.endsWith("2")) {
      ta2 = <Tab id="tab-2" className="page-content callTab" tabActive><CallMain nextPage={this.nextPageHandler} invitedContact={this.state.newInstantCallContact} /></Tab>;
    }

    if (this.state.page.endsWith("3")) {
      ta3 = <Tab id="tab-3" className="page-content callTab" tabActive><CallChat nextPage={this.nextPageHandler} callId={RaApiCalls.getActiveCall() != null ? RaApiCalls.getActiveCall().UniqueId : null} live={true} productCode={this.props.productCode} /></Tab>;
    }

    return (
      <Page id="call-page" style={{ backgroundColor: this.state.page.endsWith("1") ? "#000" : "#fff" }}>

        <Popup className="calling-popup" closeByBackdropClick={false} colorTheme="custom" opened={this.state.showcalling === true} >
          <Page>
            <Block className='call-block' strong style={{ textAlign: 'center', padding: '10%' }}>
              <h1>{this.state.newInstantCallContact != null ? this.state.newInstantCallContact.Name : ''}</h1>
              <div>{this.state.newInstantCallContact != null && this.state.newInstantCallContact.Company != null ? this.state.newInstantCallContact.Company.Name + ' - ' : ''}{this.state.newInstantCallContact != null ? this.state.newInstantCallContact.CompanyRole : ''}</div>
              <br></br>
              <div><i>connecting...</i></div>
            </Block>
            <Fab onClick={(e) => { this.closeActiveCall(); }} position="center-bottom" slot="fixed" color="red">
              <Icon ios="f7:phone_down_fill" aurora="f7:phone_down_fill" md="material:call_end"></Icon>
            </Fab>
          </Page>
        </Popup>

        <Popup className="mainpopup" closeByBackdropClick={false} opened={this.state.mainpopupshow} onPopupClosed={() => this.setState({ mainpopupshow: false })}>
          <Page>
            <Navbar title={this.state.mainpopuptitle}>
              <NavRight>
                <Link popupClose>Close</Link>
              </NavRight>
            </Navbar>
            <Block>
              {this.state.mainpopupcontent}
            </Block>
          </Page>
        </Popup>

        <Toolbar tabbar labels position='top'>
          {t1}
          {t2}
          {t3}
        </Toolbar>

        <Tabs>
          {ta1}
          {ta2}
          {ta3}
        </Tabs>
        <audio style={{ display: 'none' }} id="audio-player" controls></audio>
      </Page>
    );
  }

  mainInterface = () => { // <Page className="page-home">

    let t1 = <Link text={this.$f7.device.desktop ? "HOME" : ""} onClick={() => { this.nextPageHandler('main-1') }} tabLink="#tab-1" iconIos="f7:phone" iconAurora="f7:phone" iconMd="material:phone"></Link>;
    let t2 = <Link text={this.$f7.device.desktop ? "RECENTS" : ""} onClick={() => { this.nextPageHandler('main-2') }} tabLink="#tab-2" iconIos="f7:clock" iconAurora="f7:clock" iconMd="material:query_builder"></Link>;
    let t3 = <Link text={this.$f7.device.desktop ? "DOCUMENTS" : ""} onClick={() => { this.nextPageHandler('main-3') }} tabLink="#tab-3" iconIos="f7:paperclip" iconAurora="f7:paperclip" iconMd="material:attach_file" ></Link>;
    let t4 = <Link text={this.$f7.device.desktop ? "PROFILE" : ""} onClick={() => { this.nextPageHandler('main-4') }} tabLink="#tab-4" iconIos="f7:person" iconAurora="f7:person" iconMd="f7:person"></Link>;

    if (this.state.page.endsWith("1")) {
      t1 = <Link text={this.$f7.device.desktop ? "HOME" : ""} onClick={() => { this.nextPageHandler('main-1') }} tabLinkActive tabLink="#tab-1" iconIos="f7:phone" iconAurora="f7:phone" iconMd="material:phone"></Link>;
    }

    if (this.state.page.endsWith("2")) {
      t2 = <Link text={this.$f7.device.desktop ? "RECENTS" : ""} onClick={() => { this.nextPageHandler('main-2') }} tabLinkActive tabLink="#tab-2" iconIos="f7:clock" iconAurora="f7:clock" iconMd="material:query_builder"></Link>;
    }

    if (this.state.page.endsWith("3")) {
      t3 = <Link text={this.$f7.device.desktop ? "DOCUMENTS" : ""} onClick={() => { this.nextPageHandler('main-3') }} tabLinkActive tabLink="#tab-3" iconIos="f7:paperclip" iconAurora="f7:paperclip" iconMd="material:attach_file"></Link>;
    }

    if (this.state.page.endsWith("4")) {
      t4 = <Link text={this.$f7.device.desktop ? "PROFILE" : ""} onClick={() => { this.nextPageHandler('main-4') }} tabLinkActive tabLink="#tab-4" iconIos="f7:person" iconAurora="f7:person" iconMd="f7:person"></Link>;
    }

    let pageContent1 = (
      <Tab id="tab-1" className="page-content">
        <CurrentCalls
          nextPage={this.nextPageHandler}
          setNavBar={this.setNavBarHandler}
          currentCallsHandler={this.currentCallsHandler}
          popupHandler={this.mainPopupHandler}
          newInstantCallHandler={this.newInstantCallHandler} />
      </Tab>
    );
    let pageContent2 = <Tab id="tab-2" className="page-content"><CallsHistory nextPage={this.nextPageHandler} setNavBar={this.setNavBarHandler} chatCallIdHandler={this.chatCallIdHandler} /></Tab>;
    let pageContent3 = <Tab id="tab-3" className="page-content"><Documents nextPage={this.nextPageHandler} setNavBar={this.setNavBarHandler} /></Tab>;
    let pageContent4 = <Tab id="tab-4" className="page-content"><Settings nextPage={this.nextPageHandler} setNavBar={this.setNavBarHandler} /></Tab>;

    if (this.state.page.endsWith("1")) {
      pageContent1 = (
        <Tab id="tab-1" className="page-content" tabActive>
          <CurrentCalls
            setNavBar={this.setNavBarHandler}
            currentCallsHandler={this.currentCallsHandler}
            popupHandler={this.mainPopupHandler}
            newInstantCallHandler={this.newInstantCallHandler}
            nextPage={this.nextPageHandler} />
        </Tab>);
    }

    if (this.state.page.endsWith("2")) {
      pageContent2 = <Tab id="tab-2" className="page-content" tabActive><CallsHistory setNavBar={this.setNavBarHandler} nextPage={this.nextPageHandler} chatCallIdHandler={this.chatCallIdHandler} /></Tab>;
    }

    if (this.state.page.endsWith("3")) {
      pageContent3 = <Tab id="tab-3" className="page-content" tabActive><Documents setNavBar={this.setNavBarHandler} nextPage={this.nextPageHandler} /></Tab>;
    }

    if (this.state.page.endsWith("4")) {
      pageContent4 = <Tab id="tab-4" className="page-content" tabActive><Settings setNavBar={this.setNavBarHandler} nextPage={this.nextPageHandler} /></Tab>;
    }

    return (
      <Page pageContent={false} colorTheme="custom">

        <Popup className="incomingcall-popup" colorTheme="custom" closeByBackdropClick={false} opened={this.props.incomingCall != null && RaApiCalls.getIncomingCall() != null}>
          <Page>
            <Block className='call-block' strong style={{ textAlign: 'center', padding: '10%' }}>
              <h1>{RaApiCalls.getIncomingCaller() != null ? RaApiCalls.getIncomingCaller().Name : ''}</h1>
              <div>{RaApiCalls.getIncomingCaller() && RaApiCalls.getIncomingCaller().Company != null ? RaApiCalls.getIncomingCaller().Company.Name + ' - ' : ''}{RaApiCalls.getIncomingCaller() != null ? RaApiCalls.getIncomingCaller().CompanyRole : ''}</div>
              <br></br>
              <div><i>calling...</i></div>
            </Block>
            <Fab onClick={(e) => { this.onDeclinedCall() }} position="left-bottom" slot="fixed" text="Decline" color="red">
              <Icon ios="f7:phone_down_fill" aurora="f7:phone_down_fill" md="material:call_end"></Icon>
            </Fab>
            <Fab onClick={(e) => { this.onAcceptedCall() }} position="right-bottom" slot="fixed" text="Accept" color="green">
              <Icon ios="f7:phone_fill" aurora="f7:phone_fill" md="material:call"></Icon>
            </Fab>
          </Page>
        </Popup>

        <Popup className="mainpopup" closeByBackdropClick={false} opened={this.state.mainpopupshow} onPopupClosed={() => this.setState({ mainpopupshow: false })}>
          <Page>
            <Navbar title={this.state.mainpopuptitle}>
              <NavRight>
                <Link popupClose>Close</Link>
              </NavRight>
            </Navbar>
            <Block>
              {this.state.mainpopupcontent}
            </Block>
          </Page>
        </Popup>

        <Tabs>
          {pageContent1}
          {pageContent2}
          {pageContent3}
          {pageContent4}
        </Tabs>

        <Toolbar tabbar labels position='bottom' className="mainToolBar">
          {t1}
          {t2}
          {t3}
          {t4}
        </Toolbar>
        <audio style={{ display: 'none' }} id="audio-player" controls></audio>
      </Page>
    );
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
    //    profile: state.profile,
    activeCall: state.remoteAssistance.activeCall,
    incomingCall: state.remoteAssistance.incomingCall,
    unreadMessages: state.remoteAssistance.unreadMessages,
    notSeenPhotos: state.remoteAssistance.notSeenPhotos,
    isCordovaReady: state.remoteAssistance.isCordovaReady,
    productCode: state.remoteAssistance.productCode,
    callingState: state.remoteAssistance.callingState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToken: (token) => dispatch(RaReduxActions.setToken(token)),
    //    setUserInfo: (token, profile) => dispatch(RaReduxActions.setUserInfo(token, profile)),
    //    setActiveCall: (call) => dispatch(RaReduxActions.setActiveCall(call)),
    //connectToComunicationProvider: (trackDiv, setDataTrackCallback, setVideoTrackCallback) => dispatch(RaReduxActions.connectToComunicationProvider(trackDiv, setDataTrackCallback, setVideoTrackCallback)),
    setDataTrack: (dataTrack) => dispatch(RaReduxActions.setDataTrack(dataTrack)),
    setVideoTrack: (VideoTrack) => dispatch(RaReduxActions.setVideoTrack(VideoTrack)),
    setMustUpdateTargets: (mustUpdateTargets) => dispatch(actions.setMustUpdateTargets(mustUpdateTargets)),
    setMustUpdateHome: (mustUpdateHome) => dispatch(actions.setMustUpdateHome(mustUpdateHome)),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Home);