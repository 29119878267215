import React from 'react';
import { Preloader, Block, Col } from 'framework7-react';

const Spinner = (props) => {
    return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
        <Col style={{backgroundColor: '#fff'}}>
          <Preloader size={42}></Preloader>
        </Col>
      </Block>
    );
}

export default Spinner;