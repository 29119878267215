import React from 'react';
import { Page, List, Link, Navbar, ListItem, Searchbar, ListIndex, Col, Block, Preloader } from 'framework7-react';
import { connect } from 'react-redux';
//import RaReduxActions from '../ra-lib/redux/actions';
//import RaApiContacts from '../ra-lib/api/contacts';
import { RaApiContacts } from 'pikkart-remote-assistance';
// import moment from 'moment-timezone';

class ContactsList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      contacts: null,
      errormessage: null,
    };
  }

  render() {

    if (this.state.errormessage !== null) {
      return (
        <Page>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <h3>Error loading contacts list: {this.state.errormessage}</h3>
            </Col>
          </Block>
        </Page>
      );
    }

    if (this.state.contacts === null) {
      return (
        <Page>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <Preloader size={42}></Preloader>
            </Col>
          </Block>
        </Page>
      );
    }

    if (this.state.contacts.length === 0) {
      return (
        <Page>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <h3>Your contacts list is empty</h3>
            </Col>
          </Block>
        </Page>
      );
    }

    return ( // onListIndexSelect={this.onIndexSelect.bind(this)}
      <Page>
        <Navbar title="Select a contact">
          <Link slot="right" iconMd="material:close" onClick={(e) => { this.props.closeContacts(); }}></Link>
        </Navbar>

        <Searchbar
          searchContainer=".search-list"
          searchIn=".item-title, .item-header, .item-footer"
          disableButton={!this.$theme.aurora}
        ></Searchbar>
        <ListIndex
          indexes="auto"
          listEl=".list.contacts-list"
          scrollList={true}
          label={true}
        ></ListIndex>
        <List className="searchbar-not-found">
          <ListItem title="Nothing found" />
        </List>
        {this.getContacts()}
      </Page>

    );
  }

  /*
          <List contactsList className="search-list searchbar-found">
          <ListGroup>
            <ListItem title="A" groupTitle></ListItem>
            <ListItem header="Company / role" title="Andrea Bianchi (organizer)" footer="Skills" badge="Online">
              <Icon slot="media" icon="demo-list-icon"></Icon>
            </ListItem>
            <ListItem header="Company / role" title="Arturo Brachetti" footer="Skills" badge="Online">
              <Icon slot="media" icon="demo-list-icon"></Icon>
            </ListItem>
          </ListGroup>
        </List>

  */

  onIndexSelect(itemContent) {
    //console.log(itemContent);
    this.props.backPageClicked(itemContent)
  }


  getContacts = () => {

    if (this.state.contacts === null || this.state.contacts.length === 0) {
      return (null);
    }

    const contacts = this.state.contacts.map((item2, index2) => {

      if (item2.Groups) {
      }

      return (
        <ListItem key={"licontact" + index2}
          onClick={() => { this.onIndexSelect(item2); }}
          title={item2.Name}
          footer={(item2.Company != null ? item2.Company.Name + ' - ' : '') + item2.CompanyRole}
          href="#">
        </ListItem>
      );
    });

    return (
      <List className="search-list searchbar-found">
        {contacts}
      </List>
    );
  }

  componentDidMount = () => {
    if (this.state.contacts === null && this.props.token !== null) {
      this.fetchContactsList();
    }
  }

  componentDidUpdate = () => {
    if (this.state.contacts === null && this.props.token !== null) {
      this.fetchContactsList();
    }
  }

  fetchContactsList = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          contacts: null,
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (contacts) => {
      this.setState({ contacts: contacts, errormessage: null });
    }

    RaApiContacts.getAddressBook()
      .then(function (response) {

        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });

  };

};

// #region Redux
const mapStateToProps = () => {
  return {
    //    token: state.token,
  };
};

const mapDispatchToProps = () => {
  return {
    //    setToken: (token) => dispatch(RaReduxActions.setToken(token)),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
