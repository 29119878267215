import { connect } from 'react-redux';
import { RaUiTrackRenderer } from 'pikkart-remote-assistance';


export class TrackRender extends RaUiTrackRenderer {

}

// #region Redux
const mapStateToProps = state => {
  return {
    callTracksNodes: state.remoteAssistance.callTracksNodes,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(TrackRender);