import React from 'react';
import { Block, BlockTitle, List, ListGroup, ListItem, Card, CardContent, Col, Preloader, Icon, Sheet, PageContent, Row } from 'framework7-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CallAnnotationsView from './call-annotations-view';
import { RaApiCalls, RaStorage, RaApiAuth, RaUI } from 'pikkart-remote-assistance';
/*import RaApiCalls from '../ra-lib/api/calls/calls';
import RaStorage from '../ra-lib/storage';*/
import * as actions from '../redux/actions';
//import { start } from 'repl';
//import RouterModule from 'framework7/modules/router/router';

class CallsHistory extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      calls: null,
      errormessage: null,
      annotationscallid: null,
      messagescallid: null,
      updateIntervalId: null,
      recentCallDetailSheetOpened: false,
      recentCallSelected: null
    };
  }


  render() {

    if (this.state.errormessage !== null) {
      return (
        <div>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <h3>Error loading calls list: {String(this.state.errormessage)}</h3>
            </Col>
          </Block>
        </div>
      );
    }

    if (this.state.calls === null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    if (this.state.annotationscallid !== null) {
      return <CallAnnotationsView backPageClicked={(e) => { this.setState({ annotationscallid: null, messagescallid: null }) }} callId={this.state.annotationscallid} />
    }

    let recentCallSelectedSheet = '';
    if (this.state.recentCallSelected) {
      recentCallSelectedSheet = (
        <Sheet className="recent-call-sheet align-items-stretch"
          opened={this.state.recentCallDetailSheetOpened}
          onSheetClosed={() => { this.setState({ recentCallDetailSheetOpened: false }) }}
          swipeToClose
          backdrop>
          <BlockTitle textAlign="center" medium>Caller</BlockTitle>
          <PageContent>
            <Block textAlign="center">
              <Row>
                <Col width="30" style={{ textAlign: 'right' }}>
                  <img src={RaUI.getAvatar(this.state.recentCallSelected.Caller.Name)} alt="avatar" width="48" style={{ borderRadius: "50%" }} />
                </Col>
                <Col width="70">
                  <strong style={{ display: "block", fontSize: '120%' }}>{this.state.recentCallSelected.Caller.Name ? this.state.recentCallSelected.Caller.Name : "N.A."}</strong>
                  <div style={{ display: this.state.recentCallSelected.Caller.Company ? "block" : "none" }}>{this.state.recentCallSelected.Caller.Company ? this.state.recentCallSelected.Caller.Company.Name : null}</div>
                  <div style={{ display: this.state.recentCallSelected.Caller.CompanyRole ? "block" : "none" }}>{this.state.recentCallSelected.Caller.CompanyRole}</div>
                  <div style={{ display: this.state.recentCallSelected.Caller.Email ? "block" : "none" }}>{this.state.recentCallSelected.Caller.Email}</div>
                </Col>
              </Row>
            </Block>

            {/*
            <Block>
              <div>{(this.state.recentCallSelected.Caller != null && this.state.recentCallSelected.Caller.Company != null ?
                this.state.recentCallSelected.Caller.Company.Name + (this.state.recentCallSelected.Caller.CompanyRole ? ' - ' : '') : '') + (this.state.recentCallSelected.Caller != null
                  ? this.state.recentCallSelected.Caller.CompanyRole : '')}</div>
            </Block>
*/}
          </PageContent>
        </Sheet>);
    }

    return (
      <div>
        <List>
          {this.getCallsHistory()}
        </List>
        {recentCallSelectedSheet}
      </div>
    );
  }

  getCallsHistory = () => {
    if (this.state.calls === null || this.state.calls.length === 0) {
      return (
        <Card>
          <CardContent>
            <BlockTitle>No calls in your history</BlockTitle>
          </CardContent>
        </Card>
      );
    }

    const ret = this.state.calls.map((item, index) => {

      return (
        <ListGroup key={"group" + index}>
          <ListItem key={"callp" + index} title={item.dateName} groupTitle></ListItem>
          {this.getCallsGroup(item.calls)}
        </ListGroup>
      );
    });

    return ret;
  }

  CALL_FEEDBACK_UNANSWERED = 0;
  CALL_FEEDBACK_ACCEPTED = 1;
  CALL_FEEDBACK_DECLINED = 2;
  CALL_FEEDBACK_HANDLEDBYGROUPMEMBER = 3;

  getIcon = (call) => {
    if (call.CallerUniqueId === RaStorage.getProfile().UniqueId)
      return "f7:phone_fill_arrow_up_right";
    else if (call.Feedback === this.CALL_FEEDBACK_ACCEPTED)
      return "f7:phone_fill_arrow_down_left";
    else if (call.Feedback === this.CALL_FEEDBACK_DECLINED || call.Feedback === this.CALL_FEEDBACK_UNANSWERED)
      return "material:phone_missed";
    else if (call.Feedback === this.CALL_FEEDBACK_HANDLEDBYGROUPMEMBER)
      return "f7:phone_fill_arrow_right";
  }

  getIconColor = (call) => {
    if (call.CallerUniqueId !== RaStorage.getProfile().UniqueId
      && (call.Feedback === this.CALL_FEEDBACK_DECLINED || call.Feedback === this.CALL_FEEDBACK_UNANSWERED))
      return "red";
  }

  recentItemClick = (e, call) => {

    if (e.target.childElementCount > 0 && call.CallerUniqueId !== RaStorage.getProfile().UniqueId) {
      this.setState({ recentCallDetailSheetOpened: true, recentCallSelected: call });
    }
  }

  closeRecentSheet = () => {
    this.setState({ recentCallDetailSheetOpened: false, recentCallSelected: null });
  }

  showCallData = (call) => {
    return call.Answered === true;
  }

  getCallsGroup = (callGroup) => {

    return callGroup.map((call, index) =>
      <ListItem
        onClick={(e) => { this.recentItemClick(e, call) }}
        key={"call" + call.UniqueId + index}
        title={call.CallerUniqueId === RaStorage.getProfile().UniqueId ? call.Subject : call.Caller.Name}
        footer={moment.utc(call.StartedAt).local().format("DD/MM/YYYY HH:mm").concat(call.Feedback === this.CALL_FEEDBACK_ACCEPTED ? ' - ' + call.Duration.substring(0, call.Duration.indexOf('.')) : '')}>

        <Icon slot="media" color={this.getIconColor(call)} ios={this.getIcon(call)} aurora={this.getIcon(call)} md={this.getIcon(call)}></Icon>

        <span style={{ visibility: this.showCallData(call) ? "visible" : "hidden", opacity: call.HasPics === true ? "1" : "0.3" }} slot="after" onClick={(e) => { this.selectedAnnotationsHandler(call) }}>
          <Icon large={true} ios="f7:camera" aurora="f7:camera" md="material:camera_alt"></Icon>
        </span>
        <span slot="after">&nbsp;&nbsp;</span>
        <span style={{ visibility: this.showCallData(call) ? "visible" : "hidden", opacity: call.HasMessages === true ? "1" : "0.3" }} slot="after" onClick={(e) => { this.selectedChatHandler(call) }}>
          <Icon large={true} ios="f7:bubble_left_fill" aurora="f7:bubble_left_fill" md="material:chat_bubble"></Icon>
        </span>
      </ListItem>
    );
  }

  selectedChatHandler = (call) => {
    if (call.HasMessages === true) {
      this.props.nextPage('chatview');
      this.props.chatCallIdHandler(call.UniqueId, call.CallerUniqueId);
    }
  }

  selectedAnnotationsHandler = (call) => {
    if (call.HasPics === true) {
      this.props.nextPage('annotationView');
      this.props.chatCallIdHandler(call.UniqueId, call.CallerUniqueId)
    }
  }

  componentDidMount = () => {
    if (this.state.calls === null && this.props.token !== null) {
      this.fetchCalls();
    }

    this.resetLockedCalls();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.state.calls === null && this.props.token !== null) {
      let self = this;
      setTimeout(function () { self.fetchCalls(); }, 1000);
    }
    else {
      if (this.props.mustUpdateHome) {
        if (this.state.updateIntervalId === null) {
          this.setState({ updateIntervalId: setInterval(this.fetchCalls, 10000) });
        }
      }
    }
  }

  resetLockedCalls = () => {
    const token = RaStorage.getToken();
    if (token) {
      RaApiCalls.deleteLockedCalls()
        .catch(error => { console.error('deleteLockedCalls error', error); })
        .finally(() => {
          this.fetchProfile();
        });
    }
  }

  fetchProfile = () => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (profile) => {

    }

    RaApiAuth.getProfile()
      .then(function (response) {
        //handle success
        //console.log(response);

        if (response.result.success === true) {
          // handle success
          //response.data contiene il token
          successFn(response.data);
        } else {
          // handle error
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        //handle error
        errorFn(response.message);
      });

  };

  fetchCalls = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          calls: null,
          errormessage: errorMsg,
        });
        this.props.setMustUpdateHome(false);
      }
    }

    const successFn = (calls) => {
      const downloadedCalls = groupCallsByDate(calls.slice(0, 50));
      if (this.state.calls === null) {
        this.setState({ calls: downloadedCalls, errormessage: null });
      }
      else if (JSON.stringify(this.state.calls) !== JSON.stringify(downloadedCalls)) {
        this.setState({ calls: downloadedCalls, errormessage: null });
        this.props.setMustUpdateHome(false);
        clearHistoryInterval(this.state.updateIntervalId);
      }
    }

    const clearHistoryInterval = (intervalId) => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        this.setState({ updateIntervalId: null });
      }
    }

    const groupCallsByDate = (calls) => {
      let result = []
      let date = new Date();
      let today = moment(date).tz("Europe/Rome").format("DD/MM/YYYY");
      let yesterday = moment(date.setDate(date.getDate() - 1)).tz("Europe/Rome").format("DD/MM/YYYY");

      let lastDateName = null;
      for (var i = 0; i < calls.length; i++) {
        let call = calls[i];
        let startedAt = moment(call.StartedAt).tz("Europe/Rome").format("DD/MM/YYYY");
        let dateName = startedAt;
        if (dateName === today)
          dateName = "Today";
        else if (dateName === yesterday)
          dateName = "Yesterday";
        if (lastDateName !== dateName)
          result.push({ dateName: dateName, calls: [call] });
        else
          result[result.length - 1].calls.push(call);
        lastDateName = dateName;
      }

      return result;
    }

    RaApiCalls.getCallHistory()
      .then(function (response) {
        //handle success
        //console.log(response);

        if (response.result.success === true) {
          // handle success
          successFn(response.data);
        } else {
          // handle error
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        //handle error
        errorFn(errorMessage);
      });

  };

};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
    mustUpdateHome: state.app.mustUpdateHome,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMustUpdateHome: (setMustUpdateHome) => dispatch(actions.setMustUpdateHome(setMustUpdateHome)),
  }
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(CallsHistory);