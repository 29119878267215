import React from 'react';
import { Page, Navbar, List, ListInput, Button } from 'framework7-react';
import { connect } from 'react-redux';

class DocumentsFilter extends React.Component {

  render() {

    if (this.props.username === null || this.props.confirmcode === null) {
      return null;
    }

    return (
      <Page>
        <Navbar title="Set a filter">
        <a href="#" onClick={(e) => {this.props.nextPage("main-3")}} slot="left">&lt; Back</a>
        </Navbar>
        <List inlineLabels noHairlinesMd>
          <ListInput
            label="File name"
            type="text"
            clearButton
          >
          </ListInput>

          <ListInput
            label="Received from date"
            type="date"
            placeholder="Please choose..."
          >
          </ListInput>

          <ListInput
            label="Received to date"
            type="date"
            placeholder="Please choose..."
          >
          </ListInput>
        </List>

        <Button>Reset filters</Button>
        <Button onClick={(e) => {this.props.nextPage("main-3")}}>Apply filters</Button>
      </Page>

    );
  }

  onIndexSelect(itemContent) {
    console.log(itemContent);
  }

  componentDidUpdate = () => {
    if (this.props.username === null || this.props.confirmcode === null) {
      this.$f7router.navigate('/login/');
    }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     setToken: (token) => dispatch(actions.setToken(token)),
//   }
// };

// #endregion

export default connect(mapStateToProps, null)(DocumentsFilter);
