import React from 'react';
import { MessagesTitle, Messages, Message, Messagebar, Link, Icon, MessagebarAttachment, MessagebarSheet, MessagebarSheetImage, MessagebarAttachments } from 'framework7-react';
import { connect } from 'react-redux';
import { RaApiCallsMessages, RaCordova, RaCordovaUi, RaApiCallsCommunicationProvider } from 'pikkart-remote-assistance';
/*import RaApiCallsMessages from '../ra-lib/api/calls/message';
import RaApiCallsCommunicationProvider from '../ra-lib/api/calls/communicationProvider';*/
import moment from 'moment-timezone';

class CallChat extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            attachments: [],
            sheetVisible: false,
            typingMessage: null,
            messagesData: [],
            images: [],
            people: [],
            answers: [],
            responseInProgress: false,
            dataTrack: null,
            chatDate: null,
            chatTime: null,
            shareDocsVisible: true,
        }

    }


    render() {

        const embeddedMessage = (

            <Messages ref={(el) => { this.messagesComponent = el }}>
                <MessagesTitle><b>{this.state.chatDate}</b> {this.state.chatTime}</MessagesTitle>

                {this.state.messagesData.map((message, index) => (
                    <Message
                        key={index}
                        type={message.type}
                        image={message.image}
                        name={message.name}
                        avatar={message.avatar}
                        first={this.isFirstMessage(message, index)}
                        last={this.isLastMessage(message, index)}
                        tail={this.isTailMessage(message, index)}
                        onClickText={() => { this.messageClicked(message.text) }}
                    >
                        {message.text && (
                            <span slot="text" dangerouslySetInnerHTML={{ __html: message.text }} />
                        )}
                    </Message>
                ))}
                {this.state.typingMessage && (
                    <Message
                        type="received"
                        typing={true}
                        first={true}
                        last={true}
                        tail={true}
                        header={`${this.state.typingMessage.name} is typing`}
                        avatar={this.state.typingMessage.avatar}
                    ></Message>
                )}
            </Messages>);

        const embeddedMessageBar = (
            <Messagebar style={{ position: 'fixed' }}
                placeholder={this.placeholder}
                ref={(el) => { this.messagebarComponent = el }}
                attachmentsVisible={this.attachmentsVisible}
                sheetVisible={this.state.sheetVisible}
            >

                <div class="list-block inputs-list theme-indigo" slot="inner-start" style={{ display: this.state.shareDocsVisible ? 'inherits' : 'none' }}>
                    <div class="item-content">
                        <div class="item-media button button-raised button-fill color-indigo fileUpload">
                            <Icon className="ico_upload" md="material:attach_file"></Icon>
                            <input id="selectedFile" type="file" className="upload" />
                        </div>
                    </div>
                </div>
                <Link
                    iconIos="f7:arrow_up_fill"
                    iconAurora="f7:arrow_up_fill"
                    iconMd="material:send"
                    slot="inner-end"
                    onClick={this.sendMessageToCms.bind(this)}
                ></Link>
                <MessagebarAttachments>
                    {this.state.attachments.map((image, index) => (
                        <MessagebarAttachment
                            key={index}
                            image={image}
                            onAttachmentDelete={() => this.deleteAttachment(image)}
                        ></MessagebarAttachment>
                    ))}
                </MessagebarAttachments>
                <MessagebarSheet>
                    {this.state.images.map((image, index) => (
                        <MessagebarSheetImage
                            key={index}
                            image={image}
                            checked={this.state.attachments.indexOf(image) >= 0}
                            onChange={this.handleAttachment.bind(this)}
                        ></MessagebarSheetImage>
                    ))}
                </MessagebarSheet>
            </Messagebar>
        );

        return (
            <span>
                {embeddedMessage}
                {this.props.live && (
                    embeddedMessageBar
                )}
            </span>
        )
    }

    get attachmentsVisible() {
        const self = this;
        return self.state.attachments.length > 0;
    }
    get placeholder() {
        const self = this;
        return self.state.attachments.length > 0 ? 'Add comment or Send' : 'Message';
    }

    componentDidMount() {
        const self = this;
        self.$f7ready(() => {
            if (self.messagebarComponent) {
                self.messagebar = self.messagebarComponent.f7Messagebar;
                self.messages = self.messagesComponent.f7Messages;
            }

            if (typeof window.FileReader !== 'function') {
                console.log("The file API isn't supported on this browser yet.");
                this.setState({ shareDocsVisible: false });
            }
            else {
                self.$$('#selectedFile').on('change', function () {
                    let input = self.$$('#selectedFile')[0];
                    if (!input.files) {
                        console.log("This browser doesn't seem to support the `files` property of file inputs.");
                        self.setState({ shareDocsVisible: false });
                    }
                    else if (!input.files[0]) {
                        console.log("No file selected");
                    }
                    else {
                        let file = input.files[0];
                        if (file.size > (process.env.REACT_APP_MAX_FILES_UPLOAD_MB_LENGTH * 1024 * 1024)) {
                            let message = 'the selected file exceeds the allowed limit of ' + process.env.REACT_APP_MAX_FILES_UPLOAD_MB_LENGTH + ' MB';
                            this.$f7.dialog.alert(message, 'file too big');
                        }
                        else {
                            self.sendMessageToCms(null, file);
                        }
                    }
                });
            }
        });

        if (this.props.productCode) {
            this.sendProductCodeToMessage(this.props.productCode);
        }

        RaApiCallsMessages.getMessages(this.props.callId).then(response => {

            if (response.result.success === true) {
                let messages = response.data;

                if (messages.length > 0) {
                    const latestMessage = messages[0];
                    const date = new Date(latestMessage.Date);
                    const messageDate = this.getFormattedDate(date);

                    const messageTime = this.getFormattedTime(date);

                    this.setState({ chatDate: messageDate, chatTime: messageTime });
                }
                const messagesToSee = [];
                messages.reverse().forEach((message) => {
                    if (self.props.profile.UniqueId === message.Sender.UniqueId) { //sono il mittente del messaggio
                        messagesToSee.push({
                            date: message.Date,
                            uniqueId: message.Date,
                            sender: message.Sender,
                            type: 'sent',
                            text: message.Message,
                        });
                    }
                    else {
                        messagesToSee.push({
                            date: message.Date,
                            uniqueId: message.Date,
                            sender: message.Sender,
                            type: 'received',
                            text: message.Message,
                            name: message.Sender.Name,
                        });
                    }
                });


                self.setState({
                    // Reset attachments
                    attachments: [],
                    // Hide sheet
                    sheetVisible: false,
                    // Send message
                    messagesData: [...self.state.messagesData, ...messagesToSee],
                });

            }
        });
    }



    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.dataTrack !== null && this.props.dataTrack !== prevProps.dataTrack) {
            //console.log('chat data track' + this.props.dataTrack);
            const jsonDataTrack = JSON.parse(this.props.dataTrack);
            if (jsonDataTrack.type === 'msg') {
                let messages = this.state.messagesData;
                const arrivedMessage = jsonDataTrack.data;

                const date = new Date(arrivedMessage.date);
                const messageDate = this.getFormattedDate(date);

                const messageTime = this.getFormattedTime(date);

                this.setState({ chatDate: messageDate, chatTime: messageTime });

                const messagesToSee = [];
                messages.forEach((message) => {
                    if (this.props.profile.UniqueId === message.sender.UniqueId) { //sono il mittente del messaggio
                        messagesToSee.push({
                            date: message.date,
                            uniqueId: message.uniqueId,
                            sender: message.sender,
                            type: 'sent',
                            text: message.text
                        });
                    }
                    else {
                        messagesToSee.push({
                            date: message.date,
                            uniqueId: message.uniqueId,
                            sender: message.sender,
                            type: 'received',
                            text: message.text,
                            name: message.sender.Name,
                        });
                    }
                });

                messagesToSee.push({
                    date: arrivedMessage.date,
                    uniqueId: arrivedMessage.uniqueId,
                    sender: arrivedMessage.sender,
                    type: 'received',
                    text: arrivedMessage.text,
                    name: arrivedMessage.sender.Name,
                })


                this.setState({
                    // Reset attachments
                    attachments: [],
                    // Hide sheet
                    sheetVisible: false,
                    // Send message
                    messagesData: [...messagesToSee],
                });
                //this.setState({
                //photos: [...this.state.photos, jsonDataTrack.data],
                //  photosCount: jsonDataTrack.NewPhotosCount,
                //});
                //this.props.setTakenSnapshots(this.state.photos);

                //TODO: tenere traccia della pagina in cui mi trovo
            }
        }
    }

    getFormattedDate(date) {

        let dd = date.getDate();
        let mm = date.getMonth() + 1; //January is 0!

        const yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        return dd + '/' + mm + '/' + yyyy;
    }

    getFormattedTime(date) {
        return date.getHours() + ":" + date.getMinutes();
    }

    isFirstMessage(message, index) {
        const self = this;
        const previousMessage = self.state.messagesData[index - 1];
        if (message.isTitle) return false;
        if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
        return false;
    }
    isLastMessage(message, index) {
        const self = this;
        const nextMessage = self.state.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
    }
    isTailMessage(message, index) {
        const self = this;
        const nextMessage = self.state.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
    }
    deleteAttachment(image) {
        const self = this;
        const attachments = self.state.attachments;
        const index = attachments.indexOf(image);
        attachments.splice(index, 1);
        self.setState({ attachments });
    }
    handleAttachment(e) {
        const self = this;
        const attachments = self.state.attachments;
        const index = self.$$(e.target).parents('label.checkbox').index();
        const image = self.state.images[index];
        if (e.target.checked) {
            // Add to attachments
            attachments.unshift(image);
        } else {
            // Remove from attachments
            attachments.splice(attachments.indexOf(image), 1);
        }
        self.setState({ attachments });
    }
    messageClicked(messageText) {
        if (messageText && this.isValidURL(messageText)) {
            if (RaCordova.isCordova()) {
                RaCordovaUi.openDocumentFromLink('messageText', messageText);
            } else {
                const win = window.open(messageText, '_blank');
                win.focus();
            }
        }
    }

    sendProductCodeToMessage(productCode) {
        const messagesToSend = [];
        const self = this;

        RaApiCallsMessages.sendMessage("Product code: " + productCode).then(response => {
            if (response.result.success === true) {

                const message = {
                    text: response.data.Message,
                    date: moment.utc(response.data.Date).toDate(),
                    uniqueId: response.data.UniqueId,
                    sender: response.data.Sender,
                    name: response.data.Sender.Name,
                };

                messagesToSend.push({
                    text: response.data.Message,
                    date: moment.utc(response.data.Date).toDate(),
                    uniqueId: response.data.UniqueId,
                    sender: response.data.Sender,
                    name: response.data.Sender.Name,
                });

                self.setState({
                    // Reset attachments
                    attachments: [],
                    // Hide sheet
                    sheetVisible: false,
                    // Send message
                    messagesData: [...self.state.messagesData, ...messagesToSend],
                });

                RaApiCallsCommunicationProvider.sendMessage(
                    '{"type": "msg", "data": ' + JSON.stringify(message) + ' }');
            }
        });
    }

    sendMessageToCms(event, file) {

        this.showPreloader(true);

        const self = this;
        let message = file;
        if (!message) {
            message = self.messagebar.getValue().replace(/\n/g, '<br>').trim();
            if (message.trim().length === 0) {
                return;
            }
        }
        const messagesToSend = [];

        RaApiCallsMessages.sendMessage(message).then(response => {
            console.log(response);
            if (response.result.success === true) {

                const message = {
                    text: response.data.Message,
                    date: moment.utc(response.data.Date).toDate(),
                    uniqueId: response.data.UniqueId,
                    sender: response.data.Sender,
                    name: response.data.Sender.Name,
                };

                messagesToSend.push({
                    text: response.data.Message,
                    date: moment.utc(response.data.Date).toDate(),
                    uniqueId: response.data.UniqueId,
                    sender: response.data.Sender,
                    name: response.data.Sender.Name,
                });

                self.setState({
                    // Reset attachments
                    attachments: [],
                    // Hide sheet
                    sheetVisible: false,
                    // Send message
                    messagesData: [...self.state.messagesData, ...messagesToSend],
                });

                self.messagebar.clear();

                // Focus area
                if (response.data.Message.length) self.messagebar.focus();

                RaApiCallsCommunicationProvider.sendMessage(
                    '{"type": "msg", "data": ' + JSON.stringify(message) + ' }');
                //callActions.notifyMessageSent(self.props.dataTrack);
            }
            else {
                this.$f7.dialog.alert('an error occurred sending your message', 'error');
                console.log(response.result.message);
            }

            this.showPreloader(false);
        });
    }

    showPreloader = (show) => {
        if (show) {
            this.$f7.preloader.show('black');
        }
        else {
            this.$f7.preloader.hide();
        }
    }

    notifyMessageSend = () => {
        // Mock response
        //TODO: agganciare callback per chi sta scrivendo
        /*
        if (self.state.responseInProgress) return;
        self.setState({
            responseInProgress: true,
        })setTimeout(() => {
            const answer = self.state.answers[Math.floor(Math.random() * self.state.answers.length)];
            const person = self.state.people[Math.floor(Math.random() * self.state.people.length)];
            self.setState({
                typingMessage: {
                    name: person.name,
                    avatar: person.avatar,
                },
            });
            setTimeout(() => {
                self.setState({
                    messagesData: [...self.state.messagesData, {
                        text: answer,
                        type: 'received',
                        name: person.name,
                        avatar: person.avatar,
                    }],
                    typingMessage: null,
                    responseInProgress: false,
                });
            }, 4000);
        }, 1000);*/
    }

    isValidURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        token: state.remoteAssistance.token,
        dataTrack: state.remoteAssistance.dataTrack,
        profile: state.remoteAssistance.profile,
        unreadMessages: state.remoteAssistance.unreadMessages,
    };
};

/*const mapDispatchToProps = dispatch => {
    return {
    }
};*/

// #endregion

export default connect(mapStateToProps, null)(CallChat);