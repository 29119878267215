import React from 'react';
import { App, View } from 'framework7-react';
import routes from './routes';
import initReactFastclick from 'react-fastclick';
import axios from 'axios';

export default function (props) {

  // Valgono per tutti i verbi: GET, POST, PUT, DELETE
  axios.defaults.headers.common['Content-Type'] = 'application/json'; 
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';

  initReactFastclick();

  let theme = 'auto';
  if (document.location.search.indexOf('theme=') >= 0) {
    theme = document.location.search.split('theme=')[1].split('&')[0];
  }
  const f7Params = {
    id: 'com.pikkart.topjet',
    theme,
    routes,
    popup: {
      closeOnEscape: true,
    },
    sheet: {
      closeOnEscape: true,
    },
    popover: {
      closeOnEscape: true,
    },
    actions: {
      closeOnEscape: true,
    },
    navbar: {
      hideOnPageScroll: false,
      iosCenterTitle: false,
      mdCenterTitle: true
    },
    touch: {
      tapHold: true,
      fastClicks: true
    }
  }
  return (  //url="/welcome/" className="safe-areas" masterDetailBreakpoint={800}
    <App params={f7Params}>
      <View url="/" main loadInitialPage={true}>
      </View>
    </App>
  );
  
};
