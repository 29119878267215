import React from 'react';
import { Block, Popup, Chip, ListGroup, Card, CardHeader, CardContent, CardFooter, Link, List, ListItem, Icon, Fab, Preloader, Col } from 'framework7-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import ContactsList from './contacts-list';
import { RaReduxActions, RaApiCallsParticipants, RaApiCalls, RaApiAuth, RaStorage, RaCordova, RaCordovaPermissions } from 'pikkart-remote-assistance';

class CurrentCalls extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      calls: null,
      errormessage: null,
      showcontactslist: false,
      spinner: false,
      updateIntervalId: null,
    };
  }

  render() {
    let errorMessage = "";
    if (this.state.errormessage !== null) {
      errorMessage = (<Block className="row demo-preloaders align-items-stretch text-align-center">
        <Col>
          <p style={{ color: 'red' }}>Error loading calls: {this.state.errormessage}</p>
        </Col>
      </Block>);
    }

    /*if (this.state.errormessage !== null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3>Error loading calls list: {this.state.errormessage}</h3>
          </Col>
        </Block>
      );
    }*/


    if (this.state.spinner === true) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    if (this.state.calls === null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    return (
      <div>
        <Fab text="New call" onClick={(e) => { this.addContact() }} position="right-bottom" style={{ position: "fixed", marginBottom: '60px' }} slot="fixed" >
          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
        </Fab>

        <Block>
          {this.getOnlineCalls()}
        </Block>

        {errorMessage}


        <Popup className="contacts-popup" colorTheme="custom" opened={this.state.showcontactslist === true && this.props.incomingCall === null} onPopupClose={(e) => { this.closeContactList(); }}>
          <ContactsList backPageClicked={(contact) => { this.newInstantCallHandler(contact); }}
            closeContacts={(e) => { this.closeContactList() }} />
        </Popup>

      </div>

    );
  }

  closeContactList = () => {
    if (this.state.showcontactslist === true)
      this.setState({ showcontactslist: false })
  }

  newInstantCallHandler = (contact) => {
    if (contact === null) {
      return;
    }
    this.closeContactList();

    this.props.newInstantCallHandler(contact);
  }

  addContact() {
    this.setState({ showcontactslist: true });
  }


  getOnlineCalls = () => {
    const emptyHome = (
      <Block style={{ textAlign: "center" }}>
        <img src="./img/landing-image.png" alt="placeholder" style={{ width: '80%', maxWidth: '400px' }}></img>
        <div>
          <h1>Topjet Remote Assistance</h1>
          <p>Need assistance? Start a new call to get supported by our service.</p>
        </div>
      </Block>
    );

    if (this.state.calls === null || this.state.calls.length === 0) {
      return emptyHome;
    }

    const ret = this.state.calls.map((item, index) => {

      if (item.ScheduledAt) {
        const contacts = item.Targets.map((item2, index2) => {

          return (
            <ListItem key={"licontact" + index2}
              title={item2.Contact.Name}
              footer={item2.Contact.CompanyRole}>
            </ListItem>
          );
        });

        return (
          <Card key={"call" + item.UniqueId} style={{ marginBottom: '30px' }}>
            <CardHeader>
              <div>
                <Icon ios="f7:phone" aurora="f7:phone" md="material:phone_in_talk"></Icon>
              &nbsp;&nbsp;&nbsp;<i>{item.ScheduledAt != null ? item.Subject : 'Call in progress'}</i>
              </div>
              <Chip outline text="Online" color="blue" />
            </CardHeader>
            <CardContent>
              <List>
                <ListGroup>
                  <ListItem title="Participants" groupTitle></ListItem>
                  {contacts}
                </ListGroup>
              </List>
            </CardContent>
            <CardFooter>
              <div><i>Started at {moment(item.StartedAt).tz("Europe/Rome").format("HH:mm")}</i></div>
              <div style={{ display: 'flex' }}>
                <Link style={{ backgroundColor: '#FF3B30', borderRadius: '25%', padding: '10px', color: 'white' }} iconIos="f7:phone_down_fill" iconAurora="f7:phone_down_fill" iconMd="material:call_end" raised fill onClick={(e) => { this.endStartedCall(item); }}></Link>
                <Link style={{ backgroundColor: '#4CD964', borderRadius: '25%', padding: '10px', color: 'white', marginLeft: '20px' }} iconIos="f7:phone_fill" iconAurora="f7:phone_fill" iconMd="material:call" raised fill onClick={(e) => { this.startJoinCall(item); }}></Link>
              </div>
            </CardFooter>
          </Card>
        );
      }
      else return null;
    });


    let retHasOtherThanNull = ret.some(function (el) {
      return el !== null;
    });

    if (retHasOtherThanNull)
      return ret;
    else
      return emptyHome;
  }

  startJoinCall = (call) => {
    this.setState({ spinner: true });
    setTimeout(() => {
      if (RaCordova.isCordova()) {
        const self = this;
        RaCordovaPermissions.askMicPermission()
          .then(function (response) {
            console.log('request permissions success ' + JSON.stringify(response));
            self.fetchJoinCall(call);
          })
          .catch(function (response) {
            console.log('request permissions failed ' + JSON.stringify(response));
            self.$f7.dialog.alert('You need to grant permissions in order to start the call', "Permessions", function () {

            });
          });
      }
      else {
        this.fetchJoinCall(call);
      }
    }, 500);
  }

  fetchJoinCall = (call) => {

    const errorFn = (errorMsg) => {
      this.setState({
        errormessage: errorMsg,
        spinner: false,
      });
    }

    const successFn = () => {
      this.props.nextPage("chatinterface-2");
    }


    RaApiCallsParticipants.startToPartecipate(call)
      .then(function (response) {
        console.log(response);

        if (response.result.success === true) {
          successFn();
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });
  }

  endStartedCall = (call) => {
    this.setState({ spinner: true });

    const errorFn = (errorMsg) => {
      this.setState({
        errormessage: errorMsg,
        spinner: false,
      })
    }

    const successFn = () => {
      this.setState({
        errormessage: null,
        spinner: false,
      });

      this.fetchCalls();
    }

    RaApiCalls.declineTargetCall(call)
      .then(function (response) {
        if (response.result.success === true) {
          successFn();
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMsg) {
        errorFn(errorMsg);
      });
  }

  componentDidMount = () => {
    if (this.state.calls === null && RaApiAuth.isAuthenticated()) {
      this.fetchCalls();
    }

    let self = this;
    window.addEventListener('focus', function () {
      if (RaApiAuth.isAuthenticated()) {
        self.fetchCalls();
      }
    });

    /*  if (this.state.updateIntervalId === null)
        this.setState({ updateIntervalId: setInterval(this.fetchCalls, 5000) });*/
  }

  componentWillUnmount = () => {
    if (this.state.updateIntervalId !== null) {
      clearInterval(this.state.updateIntervalId);
      this.setState({ updateIntervalId: null });
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    /*if (this.state.calls === null && this.props.token !== null) {
      this.fetchCalls();
    }*/

    if (this.state.calls !== null && JSON.stringify(prevState.calls) !== JSON.stringify(this.state.calls)) {
      const closedCallsIds = RaStorage.getClosedCallsIds();

      const filteredCalls = this.state.calls.filter(item => !closedCallsIds.includes(item.UniqueId));


      this.setState({ calls: filteredCalls });
    }
  }

  filterByValue = (array, string) => {
    return array.filter(o =>
      Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
  }

  fetchCalls = () => {

    if (!RaApiAuth.isAuthenticated())
      return;

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          calls: [],
          errormessage: errorMsg,
          spinner: false,
        });
        let self = this;
        setTimeout(function () { self.fetchCalls() }, 2000);
      }
    }

    const currentCallsHandler = (calls) => {
      if (calls.length === 1) {
        const call = calls[0];
        const closedCallsIds = RaStorage.getClosedCallsIds();
        const isCallInClosedCalls = closedCallsIds.includes(call.UniqueId);
        if (call.IsIstantCall && !isCallInClosedCalls) {
          RaApiCalls.setIncomingCall(call);
        }
      }
    }

    const successFn = (calls) => {
      if (callsChanged(calls)) {
        currentCallsHandler(calls);
        this.setState({
          calls: calls,
          errormessage: null,
          spinner: false,
        });
      }
    }

    // WARNING questa non mi è chiara come dovesse funzionare
    const callsChanged = (calls) => {
      if (this.state.calls == null)
        return calls != null;
      else if (this.state.calls.length !== calls.length)
        return true;
      else {
        calls.map((newCall, index) => {
          let exists = this.state.calls.map((stateCall, index) => {
            if (newCall.UniqueId === stateCall.UniqueId)
              return true;
            else
              return false;
          });
          if (exists !== true)
            return true;
          else
            return false; // Non c'era
        });
        return false;
      }
    }

    RaApiCalls.getCurrentCalls()
      .then(function (response) {
        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });

  };


};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
    incomingCall: state.remoteAssistance.incomingCall,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setActiveCall: (call) => dispatch(RaReduxActions.setActiveCall(call)),
  }
};

// const mapDispatchToProps = dispatch => {
//   return {
//     cambiaTestoDummy: (testo) => dispatch(actions.cambiaTestoDummy(testo)),
//   }
// };

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CurrentCalls);
//export default connect(mapStateToProps)(CurrentCalls);