import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBn-okmL6Mf2we-8V6QnTRjAPE9dDNU0UQ",
    authDomain: "topjet-e4641.firebaseapp.com",
    databaseURL: "https://topjet-e4641.firebaseio.com",
    projectId: "topjet-e4641",
    storageBucket: "topjet-e4641.appspot.com",
    messagingSenderId: "534540818222",
    appId: "1:534540818222:web:cadcf8a6b6a3ae09e94cbf",
    measurementId: "G-KDC3BQ6Y6W"
  });

export { initializedFirebaseApp };