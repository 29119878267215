import React from 'react';
import { Page, Navbar, Link } from 'framework7-react';
import { connect } from 'react-redux';
import CallChat from './call-chat';
//import * as actions from '../redux/actions';

class CallChatView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {

        return (
            <Page>

                <Navbar title="Messages">
                    <Link slot="left" iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" onClick={(e) => { this.exitAndResetChatView(); }}></Link>
                </Navbar>

                <CallChat callId={this.props.callId} nextPage={this.nextPage}></CallChat>

            </Page>
        )
    }

    componentDidMount() {
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.incomingCall !== null /* && !this.props.live*/) {
            this.exitAndResetChatView();
        }
    }

    exitAndResetChatView = () => {
        this.props.nextPage("main-2")
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        incomingCall: state.remoteAssistance.incomingCall,
    };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     setToken: (token) => dispatch(actions.setToken(token)),
//   }
// };

// #endregion

export default connect(mapStateToProps, null)(CallChatView);