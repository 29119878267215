import React from 'react';
import { Page, LoginScreenTitle, Button, List, ListInput, PageContent, BlockFooter } from 'framework7-react';
import { connect } from 'react-redux';
import { RaApiAuth, RaNotifications, RaStorage, RaCordova } from 'pikkart-remote-assistance';
/*import RaApiAuth from '../ra-lib/api/auth';
import RaApiContacts from '../ra-lib/api/contacts';
import RaNotifications from '../ra-lib/notifications';
import RaCordova from '../ra-lib/cordova';*/

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //username: 'giovanni.zuffolini@pikkart.com',
      //    username: 'patrizio.boccazzi@pikkart.com',
      //username: 'massimiliano.marsiglietti@pikkart.com',
      usernamevalid: false,
      confirmcode: '',      //      confirmcode: '360814',
      //		confirmcode: '715541',
      errormessage: null,
    };
  }

  render() {

    if (this.props.token !== null) {
      return null;
    }

    const signinmessage = (this.state.usernamevalid === false ? "Next" : "Login");

    let li = (
      <ListInput
        inputId="emailli"
        label="E-mail"
        type="email"
        validate={true}
        placeholder="johndoe@mail.com"
        value={this.state.username === null ? '' : this.state.username}
        onInput={(e) => {
          const username = (e.target.value === "" ? null : e.target.value);
          this.setState({ username: username });
        }}
      />);

    if (this.state.usernamevalid === true) {
      li = (
        <ListInput
          label="Confirm code"
          type="tel"
          placeholder="******"
          value={this.state.confirmcode === null ? '' : this.state.confirmcode}
          onInput={(e) => {
            this.setState({ confirmcode: e.target.value });
          }}
        />
      );
    }

    let backButton = <Button onClick={(e) => { this.resetCredentials() }} >Change e-mail</Button>;

    if (this.state.usernamevalid === false) {
      backButton = null;
    }

    return ( // <Page noToolbar noNavbar noSwipeback loginScreen>
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <PageContent>
          <LoginScreenTitle>Sign in</LoginScreenTitle>
          <List form>
            {li}
            <BlockFooter>
              <br></br><br></br>
              {backButton}
              {this.state.usernamevalid === true ?
                <Button onClick={(e) => { this.sendCode() }}>Resend code</Button>
                :
                null}
              <Button fill onClick={(e) => { this.signIn() }}>{signinmessage}</Button>
              <p style={{ color: 'red' }}>{this.state.errormessage ? this.state.errormessage.message : null}</p>
              <p style={{ display: this.state.usernamevalid === true ? 'none' : 'block' }}>Please insert your e-mail to access to the remote assistance.</p>
              <p style={{ display: this.state.usernamevalid === true ? 'block' : 'none' }}>If you have been registered in our customer database you will receive a confirm code to the e-mail address specified.</p>

            </BlockFooter>
          </List>
        </PageContent>
      </Page>
    )
  }

  resetCredentials = () => {
    this.setState({
      username: null,
      usernamevalid: false,
      confirmcode: null,
      errormessage: null,
    });
  }

  sendCode = () => {

    const errorFn = (errorMsg) => {
      this.setState({
        errormessage: errorMsg,
      });

      this.showToastBottom(errorMsg);
    }

    const successFn = (token) => {
      this.setState({
        errormessage: "Code has been sent to " + this.state.username,
      });

      this.showToastBottom("Code has been sent to " + this.state.username);
    }

    RaApiAuth.sendNewCode(this.state.username, 'topjetCode')
      .then(function (response) {
        //handle success
        console.log(response);

        if (response.result.success === true) {
          // handle success
          //response.data contiene il token
          successFn(response.data);
        } else {
          // handle error
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        //handle error
        errorFn(errorMessage);
      });
  }

  signIn = () => {
    if (this.state.usernamevalid === false) {
      const emailli = document.getElementById("emailli");

      if (emailli !== null && emailli.validity.valid === true && this.state.username !== null) {
        this.setState({ usernamevalid: true });

        this.sendCode();
      }

      return;
    }

    if (this.state.confirmcode !== null) {
      //this.props.setConfirmcode(this.state.confirmcode);

      //this.setState({ spinner: true });
      this.showPreloader(true);
      this.performLogin();
    }
  }

  performLogin = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
          //spinner: false,
        });
      }

      this.showPreloader(false);
      this.showToastBottom(errorMsg);
    }

    const successFn = () => {
      //this.setState({ spinner: false });
      //this.showPreloader(false);
    }

    const hidePreloader = () => {
      this.showPreloader(false);
    }

    RaApiAuth.doLogin(this.state.username, this.state.confirmcode, "1234567890", { Code: 'topjetCode' })
      .then(function (response) {

        if (response.result.success === true) {
          // handle success
          //response.data contiene il token
          if (!RaCordova.isCordova()) {
            //RaNotifications.init_web_notifications(initializedFirebaseApp, 'BL7Zf9xd07hISZm12JViG-BH6ABk5Hw71rUCmaG0V3wMh4TasNd8sLj3MLANEiZcWFbw3_LXnlfe-JwDu30dkFk');
            RaNotifications.checkServiceRegistration()
              .then(function (successResult) {
                if (successResult === true)
                  successFn();
                else
                  errorFn('In order to receive call notifications, please enabled notification for this app');
              })
              .catch(function (errorMessage) {
                //handle error
                errorFn(errorMessage);
              });
          }
          else {
            if (RaStorage.getFirebaseToken() != null) {
              console.log("sending FIREBASE TOKEN to server");
              RaNotifications.sendTokenToServer(RaStorage.getFirebaseToken())
                .then(function () {
                  console.log("FIREBASE TOKEN to server: " + RaStorage.getFirebaseToken());
                })
                .catch(function () {
                  console.log("FIREBASE TOKEN to server: error");
                });
            } else {
              console.log("no FIREBASE TOKEN to send");
            }
          }
        } else {
          // handle error
          errorFn(response.result.message);
        }
        hidePreloader();
      })
      .catch(function (errorMessage) {
        console.log("FIREBASE TOKEN to server: error " + errorMessage);
        //handle error
        errorFn(errorMessage);
      });
  };

  showToastBottom = (text) => {
    //if (!this.toastBottom) {
    this.toastBottom = this.$f7.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    //}
    this.toastBottom.open();
  };

  showPreloader = (show) => {
    if (show) {
      this.$f7.preloader.show('black');
    }
    else {
      this.$f7.preloader.hide();
    }
  }

  // componentDidMount = () => {
  //   if (this.props.username !== null && this.props.confirmcode !== null) {
  //     this.$f7.views.main.router.back();
  //   }
  // }

  // componentDidUpdate = () => {
  //   if (this.props.username !== null && this.props.confirmcode !== null) {
  //     // this.$f7.views.main.router.back();
  //     const self = this;
  //     const router = self.$f7router;
  //     router.back();
  //   }
  // }

  // componentDidUpdate = () => {
  //   if (this.props.username !== null && this.props.confirmcode !== null) {
  //     this.$f7.views.main.router.navigate("/welcome/");
  //   }
  // }
};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
    firebaseToken: state.remoteAssistance.firebaseToken,
    isCordovaReady: state.remoteAssistance.isCordovaReady,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Login);
