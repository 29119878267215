import React from 'react';
import { Block, Col, Page, Navbar, Link } from 'framework7-react';
import CallAnnotations from './call-annotations';
import { connect } from 'react-redux';

import { RaApiCallsPics, RaApiCalls, RaCordova } from 'pikkart-remote-assistance';

class CallAnnotationsView extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
    };
  }

  render() {

    if (this.props.username === null || this.props.confirmcode === null) {
      return null;
    }

    if (this.state.errormessage !== null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3>Error loading pics: {this.state.errormessage}</h3>
          </Col>
        </Block>
      );
    }

    return (
      <Page style={{ backgroundColor: 'black' }}>
        <Navbar title="Annotations">
          <Link slot="left" iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" onClick={(e) => { this.exitAndResetAnnotationView() }}></Link>
        </Navbar>

        <div className={this.$f7.device.android && !RaCordova.isCordova() ? 'callTabAndroidChrome' : 'callTab'}>
          <div className="annotationContainer">
            <CallAnnotations callId={RaApiCalls.getActiveCall() ? RaApiCalls.getActiveCall().UniqueId : this.props.callId} onPicSelectedHandler={this.onPicSelected} onPicDeletedHandler={this.onPicDeleted} live={this.props.live}></CallAnnotations>
          </div>
        </div>
      </Page>
    );
  }


  onPicSelected = (pic) => {
    RaApiCallsPics.selectPic(pic);
  }

  componentDidMount = () => {

    if (RaApiCalls.getActiveCall() && RaApiCalls.getActiveCall().UniqueId && this.props.token !== null) {
      this.fetchPics();
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps != null
      && prevProps.selectedSnapshot !== this.props.selectedSnapshot) {
      this.setState({ selectedPhoto: this.props.selectedSnapshot });
    }
  }

  exitAndResetAnnotationView = () => {
    RaApiCallsPics.resetCallPics();
    this.props.nextPage("main-2");
  }

  fetchPics = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (photos) => {
      if (this.state.errormessage != null) {
        this.setState({
          errormessage: null,
        });
      }
    }

    RaApiCallsPics.getActiveCallPics()
      .then(function (response) {
        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });

  };

  showToastBottom = (text) => {
    this.toastBottom = this.$f7.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    this.toastBottom.open();
  };

  showPreloader = (show) => {
    if (show) {
      this.$f7.preloader.show();
    }
    else {
      this.$f7.preloader.hide();
    }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    takenSnapshots: state.remoteAssistance.takenSnapshots,
    selectedSnapshot: state.remoteAssistance.selectedSnapshot,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CallAnnotationsView);
