import React from 'react';
import { Block, BlockTitle, Link, Popover, Card, List, ListItem, CardContent, Col, Preloader, ListGroup } from 'framework7-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { RaApiDocuments, RaCordova, RaCordovaUi } from 'pikkart-remote-assistance';
//import RaApiDocuments from '../ra-lib/api/documents'
//import RouterModule from 'framework7/modules/router/router';

class Documents extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      documentsNotOrdered: null,
      documents: null,
      errormessage: null,
      spinner: false,
    };
  }

  render() {

    if (this.state.errormessage !== null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3>Error loading documents list: {String(this.state.errormessage)}</h3>
          </Col>
        </Block>
      );
    }

    if (this.state.spinner === true) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    if (this.state.calls === null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    return (
      <div>
        {this.getDocuments()}
      </div>

    );
  }

  getDocuments = () => {
    if (this.state.documents === null || this.state.documents.length === 0) {
      return (
        <Card>
          <CardContent>
            <BlockTitle>No documents in your folder</BlockTitle>
          </CardContent>
        </Card>
      );
    }

    let docscards = [];
    Object.keys(this.state.documents).forEach(key => {
      const docslist = this.state.documents[key].map((item, index) => {
        return (
          <ListItem className='long-touch' external target="_blank" key={"doc-" + item.FileName} title={item.FileName} subtitle={moment(item.ReceivedAt).tz("Europe/Rome").format("DD/MM/YYYY")} onClick={(e) => { this.documentClickHandler(item.Document) }}>
            <Link popoverOpen={".popover-menu-" + item.UserUniqueId} key={"doc-icon-" + item.FileName} id={item.Document.FileName} slot="media" iconIos="f7:square_grid_4x3_fill" iconAurora="f7:square_grid_4x3_fill" iconMd="material:more_vert" onClick={this.optionMenuClickHandler}></Link>

          </ListItem>
        )
      });

      const docsPopover = this.state.documents[key].map((item, index) => {
        return (
          <Popover className={"popover-menu-" + item.UserUniqueId} key={'popover-menu-key-' + item.UserUniqueId}>
            <List>
              <ListItem style={{ display: 'none' }} link="#" popoverClose title="Rename" key={'rename-key-' + item.UserUniqueId} onClick={this.renameFile} />
              <ListItem link="#" popoverClose title="Delete" key={'delete-key-' + item.UserUniqueId} onClick={this.deleteFile} />
            </List>
          </Popover>
        )
      });

      docscards.push(
        <div key={'key-' + key} className={key}>
          <List>
            <ListGroup title={key} key={'group-key-' + key}></ListGroup>
            <ListItem key={"title" + key} title={key} groupTitle></ListItem>
            {docslist}
          </List>
          {docsPopover}
        </div>);

    });



    return <div>{docscards}</div>;
  }

  renameFile = (e) => {
    alert('rename: ' + this._selectedItem);
  }

  deleteFile = (e) => {
    this.$f7.dialog.confirm('Are you sure you want to delete this document?', 'Yes', () => {
      this.setState({ spinner: true });
      RaApiDocuments.deleteDocument(this._selectedItem)
        .then(function (response) {
          if (response.result.success === true) {
            successFn();
          } else {
            errorFn(response.result.message);
          }
        })
        .catch(function (response) {
          errorFn(response.message);
        });

      const errorFn = (errorMsg) => {
        console.log(errorMsg);
      }

      const successFn = () => {
        let documents = this.state.documentsNotOrdered;
        this.state.documentsNotOrdered.forEach((userDocument, index) => {
          if (userDocument.Document.FileName === this._selectedItem) {
            remove(documents, index);
          }
        });

        let docs = documents.map((item, index) => {
          return {
            date: moment(item.ReceivedAt).tz("Europe/Rome").format("DD/MM/YYYY"),
            document: item,
            spinner: false,
          }
        });

        let docsbydate = {};
        for (const doc of docs) {
          if (typeof docsbydate[doc.date] === "undefined") {
            docsbydate[doc.date] = [doc.document];
          } else {
            docsbydate[doc.date].push(doc.document);
          }
        }
        this.setState({
          documentsNotOrdered: documents,
          documents: docsbydate,
          errormessage: null,
          spinner: false,
        });
        /*this.state.document
        */
      }

      const remove = (array, index) => {
        if (index !== -1) {
          array.splice(index, 1);
        }
      }
    });
  }


  static _selectedItem = null;

  documentClickHandler = (doc) => {
    if (RaCordova.isCordova()) {
      RaCordovaUi.openDocumentFromLink(doc.FileName, doc.FileUrl);
    } else {
      const win = window.open(doc.FileUrl, '_blank');
      win.focus();
    }
  }

  optionMenuClickHandler = (e) => {
    this._selectedItem = e.currentTarget.id;
  }

  tapHoldHandler = (e) => {
    this._selectedItem = e.currentTarget.id;
  }

  componentDidMount = () => {
    this.setState({ spinner: true });

    if (this.state.documents === null && this.props.token !== null) {
      this.fetchDocuments();
    }
  }

  componentDidUpdate = () => {
    if (this.state.documents === null && this.props.token !== null) {
      let self = this;
      setTimeout(function () { self.fetchDocuments(); }, 1000);
    }
    this.$$('.long-touch').off('taphold', this.tapHoldHandler);
    this.$$('.long-touch').on('taphold', this.tapHoldHandler);
  }

  fetchDocuments = () => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          documentsNotOrdered: null,
          documents: null,
          errormessage: errorMsg,
          spinner: false,
        });
      }
    }

    const successFn = (documents) => {

      let docs = documents.map((item, index) => {
        return {
          date: moment(item.ReceivedAt).tz("Europe/Rome").format("DD/MM/YYYY"),
          document: item,
          spinner: false,
        }
      });
      let docsbydate = {};
      for (const doc of docs) {
        if (typeof docsbydate[doc.date] === "undefined") {
          docsbydate[doc.date] = [doc.document];
        } else {
          docsbydate[doc.date].push(doc.document);
        }
      }
      this.setState({
        documentsNotOrdered: documents,
        documents: docsbydate,
        errormessage: null,
        spinner: false,
      });
    }

    RaApiDocuments.getDocuments().then(function (response) {
      //handle success
      //console.log(response);

      if (response.result.success === true) {
        // handle success
        //response.data contiene il token
        successFn(response.data);
      } else {
        // handle error
        errorFn(response.result.message);
      }
    })
      .catch(function (errorMessage) {
        //handle error
        errorFn(errorMessage);
      });

  };

};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     cambiaTestoDummy: (testo) => dispatch(actions.cambiaTestoDummy(testo)),
//   }
// };

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps)(Documents);