import Home from './containers/home.jsx';
import Login from './containers/login.jsx';
import ContactsList from './containers/contacts-list.jsx';
import CallAnnotationsView from './containers/call-annotations-view.jsx';
import CallChatView from './containers/call-chat-view.jsx';
import DocumentsFilter from './containers/documents-filter.jsx';

import NotFound from './pages/404.jsx';

// Pages
export default [
  // Index page
  {
    path: '/',
    component: Home,
  },
  {
    path: '/login/',
    component: Login,
  },
  {
    path: '/contacts-list/',
    component: ContactsList,
  },
  {
    path: '/documents-filter/',
    component: DocumentsFilter,
  },
  {
    path: '/call-annotations-view/',
    component: CallAnnotationsView,
  },
  {
    path: '/call-chat-view/',
    component: CallChatView,
  },
  
  // Default route (404 page). MUST BE THE LAST
  {
    path: '(.*)',
    component: NotFound,
  },
];
