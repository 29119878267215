import React from 'react';
import { Block, Col, Preloader, Fab, Icon } from 'framework7-react';
import { connect } from 'react-redux';
import {RaApiCallsPics, RaApiCallsPicsAnnotations, RaApiCalls, RaUIAnnotationCanvas} from 'pikkart-remote-assistance';

class CallAnnotations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      spinner: false,
      annotations: [],
    };
  }

  render() {

    if (this.state.errormessage !== null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3> {'Error loading images list:'+this.state.errormessage}</h3>
          </Col>
        </Block>
      );
    }

    if (this.props.takenSnapshots === null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    return (
      <div>
        <div style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
          
          {this.getSelectedImage()}
        </div>
        {!this.props.live && this.getPictures()}
      </div>

    );
    // {this.state.selectedImage}
  }

  getSelectedImage = () => {
    if (this.props.selectedSnapshot) {
      if (this.state.spinner === true) {
        return (
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <Preloader size={42}></Preloader>
            </Col>
          </Block>
        );
      }
      else {/*
        const annCanvas = React.createElement('RaUIAnnotationCanvas',
        {
          live:this.props.live,
          backgroundImage:this.props.selectedSnapshot,
          imageAnnotations:this.state.annotations,
          callId:this.props.callId,
          dataTrack:this.props.dataTrack,
          myProfile:this.props.myProfile,
          alt:"annotabile"
        }, 
        null);
        //this.props.onPicDeletedHandler(this.state.selectedImage);
        return annCanvas;*/
        
        return <RaUIAnnotationCanvas
           live={this.props.live}
           cancelAnnotationButton={
          
            <Fab position="right-top" slot="fixed" color='gray' style={{marginTop: 0}}>
              <Icon ios="f7:arrow_uturn_left" aurora="f7:arrow_uturn_left" md="material:undo"></Icon>
            </Fab>}

           backgroundImage={this.props.selectedSnapshot}
           imageUniqueId={this.props.selectedSnapshot.UniqueId}
           callId={this.props.callId}
           dataTrack={this.props.dataTrack}
           myProfile={this.props.myProfile}
           $$={this.$$}
           alt="annotabile" />
      }
    }

    return null;
  }

  showImage = (img) => {

    this.setState({ spinner: true });
    if (img !== null)
      this.fetchAnnotationsForImage(img);

    if ((this.props.live && RaApiCalls.amICallController()) || !this.props.live) {
      RaApiCallsPics.selectPic(img);
    }
  }

  getPictures = () => {

    if (!this.props.takenSnapshots || this.props.takenSnapshots === null || this.props.takenSnapshots.length === 0) {
      return (null);
    }

    const images = this.props.takenSnapshots.map((item, index) => {

      return (
        <div key={"swiperslide" + index} style={{textAlign: 'initial', marginRight: '15px'}}>
          <span className="picIndex"># {index + 1}</span><br></br>
          <img src={item.ImageUrl} alt="placeholder" onClick={(e) => 
            { 
              if ((this.props.live && RaApiCalls.amICallController()) || !this.props.live) 
              {
                this.showImage(item) 
              }
            }} />

          {(this.props.live && RaApiCalls.amICallController()) && (
            <span class="badge" onClick={(e) => {
              this.deletePic(item.UniqueId);
            }}>X</span>
          )}
        </div>
      );
    });

    return (
      <div className="gallery-tabbar">
        <div className='picsGallery' style={{ position: 'fixed' }}>
          {images}
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    if (!this.props.live && (!this.props.takenSnapshots || this.props.takenSnapshots === null || this.props.takenSnapshots.length === 0)
      && this.props.callId) {
      this.fetchPicturesList();
    }
    else if (this.props.live && this.props.takenSnapshots && this.props.takenSnapshots.length > 0) {
      if (this.props.onPicSelectedHandler)
        this.props.onPicSelectedHandler(this.props.selectedSnapshot);
    }

    if (this.props.live) {
      RaApiCallsPics.sendSelectedPicRequestMessage();
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    
    if (!this.props.live
        && (prevProps === null || this.props.callId !== prevProps.callId)) 
      this.fetchPicturesList();
    else
    {
      if (prevProps === null || this.props.takenSnapshots !== prevProps.takenSnapshots)
        this.setState({ errormessage: null });
    }  
/*
    if (this.props.dataTrack !== null) {
      const jsonDataTrack = JSON.parse(this.props.dataTrack);
      if (this.props.live && RaApiCalls.amICaller()) {
        if (jsonDataTrack.type === 'askAnnotationSelection') {
          RaApiCallsCommunicationProvider.sendMessage('{"type": "annotationSelection", "data": ' + JSON.stringify(this.state.selectedImage) + ' }');
        }
      }
      if (this.props.dataTrack !== prevProps.dataTrack) {
        if (jsonDataTrack.type === 'annotationSelection' && jsonDataTrack.data !== null) {
          this.setState({ spinner: true });
          this.fetchAnnotationsForImage(jsonDataTrack.data);
        }
      }
    }*/

    if (prevProps.selectedSnapshot !== this.props.selectedSnapshot && this.props.selectedSnapshot) {
      this.showImage(this.props.selectedSnapshot);
    }
  }

  fetchPicturesList = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (images) => {
      if (images.length > 0) {
        this.showImage(images[0])
      }
      else {
        this.showImage(null);
      }
      this.setState({ errormessage: null });
    }

    RaApiCallsPics.getCallPics(this.props.callId)
      .then(function (response) {
        //handle success
        console.log(response);

        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        //handle error
        errorFn(response.message);
      });

  };

  fetchAnnotationsForImage = (image) => {

    let self = this;
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        self.setState({
          spinner: false,
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (annotations) => {
      self.setState({ annotations: annotations, spinner: false });
    }

    RaApiCallsPicsAnnotations.getPicAnnotations(this.props.callId, image.UniqueId).then(function (response) {
      if (response.result.success === true) {
        successFn(response.data);
      } else {
        errorFn(response.result.message);
      }
    })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  }

  deletePic = (picId) => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          spinner: false,
          errormessage: errorMsg,
        });
      }
    }

    const successFn = () => {

      this.setState({ spinner: false, errormessage: null });

    }

    this.$f7.dialog.confirm('Do you want to delete this pic?', 'Pics', () => {
      this.setState({ spinner: true });
      RaApiCallsPics.deletePic(picId)
        .then(function (response) {
          if (response.result.success === true) {
            successFn();
          } else {
            errorFn(response.result.message);
          }
        })
        .catch(function (response) {
          //handle error
          errorFn(response.message);
        });
    })
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    takenSnapshots: state.remoteAssistance.takenSnapshots,
    selectedSnapshot: state.remoteAssistance.selectedSnapshot,
    dataTrack: state.remoteAssistance.dataTrack,
    myProfile: state.remoteAssistance.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CallAnnotations);
