import appActionTypes from './actionTypes';


export const setOrientation = (orientation) => {
  return {
    type: appActionTypes.SET_ORIENTATION,
    orientation: orientation,
  };
}

export const setMustUpdateTargets = (mustUpdateTargets) => {
  return {
    type: appActionTypes.SET_MUST_UPDATE_TARGETS,
    mustUpdateTargets: mustUpdateTargets,
  }
}

export const setMustUpdateHome = (mustUpdateHome) => {
  return {
    type: appActionTypes.SET_MUST_UPDATE_HOME,
    mustUpdateHome: mustUpdateHome,
  }
}

export const setActiveCallContacts = (activeCallContacts) => {
  return {
    type: appActionTypes.SET_ACTIVE_CALL_CONTACTS,
    activeCallContacts: activeCallContacts,
  }
}
  
