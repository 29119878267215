import React from 'react';
import { Link, Icon, Block, Col, Preloader, Popup } from 'framework7-react';
import { connect } from 'react-redux';
import { RaReduxActions, RaApiCalls, RaApiAuth, RaApiCallsTargets } from 'pikkart-remote-assistance';
import * as actions from '../redux/actions';
import Commons from '../components/Commons';
import Participants from './participants';
import ContactsList from './contacts-list';

class CallMain extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      contacts: null,
      errormessage: null,
      showcontactslist: false,
      spinner: false,
      micMuted: false,
      audioMuted: false,
      invitedContact: props.invitedContact,
      updateIntervalId: null,
    };
  }

  render() {

    //if (this.props.username === null || this.props.confirmcode === null) {
    if (!RaApiAuth.isAuthenticated()) {
      return null;
    }

    let minh = window.innerHeight;

    if (window.innerWidth < window.innerHeight) {
      minh = window.innerWidth;
    }


    if (this.state.errormessage !== null) {
      return (

        <span>
          <Block style={{ marginTop: (minh * 0.1).toString() + "px" }}></Block>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <h3>Error loading contacts list: {this.state.errormessage}</h3>
            </Col>
          </Block>
          <br></br><br></br>
          <div className='call-commands-container'>
            <Link className="fabIconLink fab-active" color='red' onClick={this.closeCall}>
              <Icon ios="f7:phone_down_fill" aurora="f7:phone_down_fill" md="material:call_end"></Icon>
            </Link>
          </div>
        </span>
      );
    }


    if (this.state.spinner === true && this.props.comunicationProviderData == null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    /*if (this.state.contacts !== null) {

      const contacts = this.state.contacts.map((item, index) => {
        return (
          <ListItem
            key={"licontact" + index}
            title={item.Contact.Name}
            footer={(item.Contact.Company != null ? item.Contact.Company.Name + ' - ' : '') + item.Contact.CompanyRole}>
            <img slot="media" alt="avatar" src={RaUI.getAvatar(item.Contact.Name)} width="48" style={{ borderRadius: "50%" }} />
            <Chip outline text={RaApiCallsTargets.getTargetStatus(item.Status)} slot="after" />
          </ListItem>
        )
      });

      return (
        <span>
          <Block style={{ marginTop: (minh * 0.1).toString() + "px" }}>
            <BlockTitle>Participants</BlockTitle>
            {/*
            <List>
              {contacts}
              {/////*
              <ListItem header="Company / role" title="Name Surname (organizer)" footer="Skills">
                <Icon slot="media" icon="demo-list-icon"></Icon>
                <Icon style={{ color: "green" }} slot="after" ios="f7:checkmark_circle" aurora="f7:checkmark_circle" md="f7:check_circle_outline"></Icon>
              </ListItem>              
              <ListItem header="Company / role" title="Name Surname" footer="Skills">
                <Icon slot="media" icon="demo-list-icon"></Icon>
                <span style={{ color: "red" }} slot="after">Offline</span>
              </ListItem>
              <ListItem header="Company / role" title="Name Surname" footer="Skills">
                <Icon slot="media" icon="demo-list-icon"></Icon>
                <span style={{ color: "red" }} slot="after">Offline</span>
              </ListItem>
              <ListItem header="Company / role" title="Name Surname" footer="Skills">
                <Icon slot="media" icon="demo-list-icon"></Icon>
                <Icon style={{ color: "green" }} slot="after" ios="f7:checkmark_circle" aurora="f7:checkmark_circle" md="f7:check_circle_outline"></Icon>
              </ListItem>
            </List>////}
          </Block>
          <br></br><br></br>
          <div className='call-commands-container'>
            <Link className="fabIconLink fab-active" style={{ marginRight: '30px' }} onClick={this.muteUnmuteMic}>
              <Icon ios={this.state.micMuted ? "f7:mic_slash" : "f7:mic"} aurora={this.state.micMuted ? "f7:mic_slash" : "f7:mic"} md={this.state.micMuted ? "f7:mic_slash" : "f7:mic"}></Icon>
            </Link>

            <Link className="fabIconLink fab-active" style={{ marginRight: '30px' }} onClick={this.addUserToRoom}>
              <Icon ios="f7:person_badge_plus" aurora="f7:person_badge_plus" md="f7:person_add"></Icon>
            </Link>

            <Link className="fabIconLink fab-active" color='red' onClick={this.closeCall}>
              <Icon ios="f7:phone_down_fill" aurora="f7:phone_down_fill" md="material:call_end"></Icon>
            </Link>
          </div>
          <Popup className="contacts-popup" colorTheme="custom" opened={this.state.showcontactslist === true} onPopupClose={(e) => { this.closeContactList(); }}>
            <ContactsList
              backPageClicked={(contact) => { this.inviteUserHandler(contact) }}
              closeContacts={(e) => { this.closeContactList() }} />
          </Popup>
        </span>
      );
    } else {
    }*/


    return (
      <span>
        <Block style={{ marginTop: (minh * 0.1).toString() + "px" }}></Block>

        <Participants />

        <br></br><br></br>
        <div className='call-commands-container'>
          <Link className="fabIconLink fab-active" style={{ marginRight: '30px' }} onClick={this.muteUnmuteMic}>
            <Icon ios={this.state.micMuted ? "f7:mic_slash" : "f7:mic"} aurora={this.state.micMuted ? "f7:mic_slash" : "f7:mic"} md={this.state.micMuted ? "f7:mic_slash" : "f7:mic"}></Icon>
          </Link>

          <Link className="fabIconLink fab-active" style={{ marginRight: '30px' }} onClick={this.addUserToRoom}>
            <Icon ios="f7:person_badge_plus" aurora="f7:person_badge_plus" md="f7:person_add"></Icon>
          </Link>

          <Link className="fabIconLink fab-active" color='red' onClick={this.closeCall}>
            <Icon ios="f7:phone_down_fill" aurora="f7:phone_down_fill" md="material:call_end"></Icon>
          </Link>
        </div>
        <Popup className="contacts-popup" colorTheme="custom" opened={this.state.showcontactslist === true} onPopupClose={(e) => { this.closeContactList(); }}>
          <ContactsList
            backPageClicked={(contact) => { this.inviteUserHandler(contact) }}
            closeContacts={(e) => { this.closeContactList() }} />
        </Popup>
      </span>);
  }

  closeContactList = () => {
    if (this.state.showcontactslist === true)
      this.setState({ showcontactslist: false })
  }

  closeCall = () => {
    try {
      RaApiCalls.closeActiveCall();
      this.props.nextPage("main-1");
    }
    catch (e) {
      this.setState({ errormessage: e.message });
    }
  }

  muteUnmuteAudio = () => {
    RaApiCalls.setMuteUnmuteAudio(this.state.audioMuted);

    const audioMuted = !this.state.audioMuted;
    this.setState({ audioMuted: audioMuted });
  }

  muteUnmuteMic = () => {
    RaApiCalls.setMuteUnmuteMic();

    const micMuted = !this.state.micMuted;
    this.setState({ micMuted: micMuted });
  }

  addUserToRoom = () => {
    this.setState({ showcontactslist: true });
  }

  inviteUserHandler = (contact) => {
    let self = this;
    setTimeout(function () {
      if (contact !== null) {
        self.fetchInviteUser(contact);
      }
    }, 1000);
  }

  fetchInviteUser = (contact) => {

    this.setState({ spinner: true, invitedContact: contact, showcontactslist: false });
  }

  onIndexSelect(itemContent) {
    console.log(itemContent);
  }

  componentDidMount = () => {
    if (this.state.errormessage === null) {
      this.setState({ spinner: true });
      this.fetchContactsList();
    }

    RaApiCalls.connectToCall(Commons.shareUserVideoTrack(), true)
      .catch(error => {
        if (this.props.connectToCallError) {
          this.props.connectToCallError(error);
        }
      });
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    /*  if (this.props.comunicationProviderData && this.state.spinner === true) {
        this.setState({ spinner: false });
      }*/

    if (prevState.invitedContact === null &&
      this.state.invitedContact !== null) {


      const errorFn = (errorMsg, errorCode) => {
        if (errorMsg !== this.state.errormessage) {


          if (errorCode === 404)
            errorMsg = this.state.newInstantCallContact.Name + ' is offline at the moment.';
          else if (errorCode === 409)
            errorMsg = this.state.newInstantCallContact.Name + ' is occupied at the moment.';

          if (errorMsg && errorMsg.length > 100)
            errorMsg = errorMsg.substring(0, 100) + '...';

          this.$f7.dialog.alert(errorMsg, 'Unable to call');

        }
      }

      const successFn = () => {
        this.fetchContactsList();
        this.setState({ spinner: false, errormessage: null });
      }

      RaApiCallsTargets.addTargetToActiveCall(this.state.invitedContact.UniqueId)
        .then(function (response) {
          console.log('Add Target To Active Call result: ' + response);

          if (response.result.success === true) {
            successFn();
          } else {
            errorFn(response.result.message, response.result.code);
          }
        })
        .catch(function (response) {
          errorFn(response.message);
        });
    }

    if (this.props.mustUpdateTargets) {
      if (this.state.updateIntervalId === null) {
        this.setState({ updateIntervalId: setInterval(this.fetchUpdateContacts, 5000) });
      }
    }
  }

  fetchUpdateContacts = () => {
    this.fetchContactsList();
    this.updateNewUserState(this.state.invitedContact);
  }

  fetchContactsList = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
        this.props.setActiveCallContacts(null);
      }
      this.props.setMustUpdateTargets(false);
    }

    const successFn = (contacts) => {
      if (this.props.activeCallContacts === null) {
        this.setState({ errormessage: null });
        this.props.setActiveCallContacts(contacts);
      }
      else if (JSON.stringify(this.props.activeCallContacts) !== JSON.stringify(contacts)) {
        this.setState({ errormessage: null });
        this.props.setActiveCallContacts(contacts);
        this.props.setMustUpdateTargets(false);
        clearHistoryInterval(this.state.updateIntervalId);
      }
    }

    const clearHistoryInterval = (intervalId) => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        this.setState({ updateIntervalId: null });
      }
    }

    RaApiCallsTargets.getActiveCallTargets()
      .then(function (response) {
        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });

  };

  updateNewUserState = (invitedContact) => {
    if (invitedContact !== null) {
      console.log(invitedContact);

      this.setState({ invitedContact: null });
      this.props.setMustUpdateTargets(false);
    }
    else {
      console.log('invitedContact === null');
    }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
    profile: state.remoteAssistance.profile,
    comunicationProviderData: state.remoteAssistance.comunicationProviderData,
    mustUpdateTargets: state.app.mustUpdateTargets,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setActiveCall: (call) => dispatch(RaReduxActions.setActiveCall(call)),
    //   setUnreadMessages: (messages) => dispatch(actions.setUnreadMessages(messages)),
    //    setTakenSnapshots: (takenSnapshots) => dispatch(actions.setTakenSnapshots(takenSnapshots)),
    //    setSelectedSnapshot: (selectedSnapshot) => dispatch(actions.setSelectedSnapshot(selectedSnapshot)),
    setMustUpdateTargets: (mustUpdateTargets) => dispatch(actions.setMustUpdateTargets(mustUpdateTargets)),
    setActiveCallContacts: (contacts) => dispatch(actions.setActiveCallContacts(contacts)),
  }
};
// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CallMain);
