import React from 'react';
import { Icon, Block, Col } from 'framework7-react';
import { connect } from 'react-redux';
import { RaUI, RaApiCalls, RaUITracksNodesManager } from 'pikkart-remote-assistance';
import TrackRender from '../track-renderer'

class RemoteVideoStream extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      showVideoLowStreamingMessage: false,
      trackSizeDetected: false
    };
  }

  render() {

    let minh = window.innerHeight;

    if (window.innerWidth < window.innerHeight) {
      minh = window.innerWidth;
    }

    if (this.state.errormessage !== null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3>Error loading video: {this.state.errormessage}</h3>
          </Col>
        </Block>
      );
    }

    return (
      <div>

        <div id="videoLowStreamingMessage" style={{ marginTop: '100px', color: '#fff', textAlign: 'center', display: this.state.showVideoLowStreamingMessage ? 'block' : 'none' }}>waiting for video streaming...</div>
        <div style={{ textAlign: 'center', display: (this.state.trackSizeDetected === true && this.state.renderingError == null ? 'block' : 'none') }}>
          <TrackRender></TrackRender>
          <Icon style={{ display: "none", marginLeft: "auto", marginRight: "auto" }} size={minh * 0.5} ios="f7:videocam" aurora="f7:videocam" md="material:videocam"></Icon>
          {/* {fab} */}
        </div>
        <canvas id="videoCanvas" style={{ display: "none" }}></canvas>

      </div>

    );
  }

  onIndexSelect(itemContent) {
    console.log(itemContent);
  }

  getVideoCropSizeCallback = () => {
    return { width: this.$$(window).width(), height: this.$$(window).height() };
  }

  cropRemoteVideoPlaceHolder = (getVideoCropSizeCallback, trackDimensions) => {

    console.log('cropRemoteVideoPlaceHolder');

    if (!getVideoCropSizeCallback) {
      console.error('getVideoCropSizeCallback not defined');
      return;
    }

    let self = this;

    let cropSize = getVideoCropSizeCallback();

    let trackWidth = null;
    let trackHeight = null;

    if (trackDimensions) {
      trackWidth = trackDimensions.width;
      trackHeight = trackDimensions.height;
    }

    console.log('cropRemoteVideoPlaceHolder trackWidth:' + trackWidth + ' trackHeight:' + trackHeight);

    if (trackWidth && trackHeight) {
      if (self.state.showVideoLowStreamingMessage === true)
        self.setState({ showVideoLowStreamingMessage: false });

      let videoCropData = RaUI.getElementFitData(cropSize.width, cropSize.height, trackWidth, trackHeight);

      if (sessionStorage.getItem('aspectRatio') !== videoCropData.aspectRatio) {
        self.$$("#" + RaUITracksNodesManager.getTrackNodeId(self.props.participantId) + ' > video').attr("style", "width:" + videoCropData.width + "px;height:" + videoCropData.height + "px;margin-top:" + videoCropData.margin.top + "px;margin-left:" + videoCropData.margin.left + "px;overflow: hidden");
        sessionStorage.setItem('aspectRatio', videoCropData.aspectRatio);
        if (self.state.trackSizeDetected !== true) {
          self.setState({ trackSizeDetected: true });
          if (self.props.onTrackSizeDetected)
            self.props.onTrackSizeDetected();
        }
      }
    }
    else {
      if (self.state.showVideoLowStreamingMessage !== true)
        self.setState({ showVideoLowStreamingMessage: true });
    }
  }

  componentDidMount = () => {

    console.log('REMOTE PARTICIPANT:' + this.props.participantId);

    RaApiCalls.onParticipantTrackDimensionChanged = (track, participantId) => {
      console.log("onParticipantTrackDimensionChanged: " + participantId, track);
      let self = this;
      if (participantId === this.props.participantId) {
        self.cropRemoteVideoPlaceHolder(self.getVideoCropSizeCallback, track.dimensions);
      }
    }
  }

  showToastBottom = (text) => {
    this.toastBottom = this.$f7.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    this.toastBottom.open();
  };

  showPreloader = (show) => {
    if (show) {
      this.$f7.preloader.show();
    }
    else {
      this.$f7.preloader.hide();
    }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    token: state.remoteAssistance.token,
    comunicationProviderData: state.remoteAssistance.comunicationProviderData,
    videoScreenState: state.remoteAssistance.videoScreenState,
    /*  videoTrack: state.remoteAssistance.videoTrack*/
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RemoteVideoStream);
