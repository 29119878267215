import React from 'react';
import { Block, Button } from 'framework7-react';
import { connect } from 'react-redux';
import { RaStorage, RaCordovaNet, RaApiCalls, RaUITracksNodesManager, RaCordova, RaCordovaPermissions, RaApiCallsCommunicationProvider, RaUI, RaMedia } from 'pikkart-remote-assistance';
import Spinner from '../../components/Spinner';
import TrackRender from '../track-renderer'

const RENDERDEVICE_IPCAM = "RENDERDEVICE_IPCAM";
const RENDERDEVICE_LOCALDEVICE = "RENDERDEVICE_LOCALDEVICE";

class LocalVideoStream extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trackSizeDetected: false,
            renderingError: null,
            renderingErrorCode: null
        };

        this.renderingErrorCodes = ['IPCAM_ROUTER', 'IPCAM_NOTCONNECTED'];

    }

    getLocalParticipantTracksNode = () => {
        return document.getElementById(RaStorage.getProfile().UniqueId);
    }


    render() {

        let spinner = null;
        if (this.state.trackSizeDetected !== true && this.state.renderingError === null
            && (!this.props.showSpinnerHandler || this.props.showSpinnerHandler()))
            spinner = (<Spinner></Spinner>);

        let errorMessage = null;
        let settingsButton = null;
        if (this.state.renderingError !== null) {
            if (this.state.renderingErrorCode !== null) {
                settingsButton = (<div style={{ marginTop: '30px', textAlign: 'center' }}><Button fill color='gray' style={{ display: 'inline-block' }} onClick={(e) => {
                    if (RaCordova.isCordova()) {
                        RaCordovaNet.openWiFiSettings(function () { }, function (error) { console.error(error); });
                    }
                }}>SETTINGS...</Button></div>);
            }

            errorMessage = (
                <Block style={{ marginTop: 0, paddingTop: '150px' }}>
                    <p style={{ textAlign: 'center', color: 'white' }}>
                        {this.state.renderingError}
                    </p>
                    {settingsButton}
                </Block>
            );
        }

        let offLineVideoTrack = null;
        if (!RaApiCalls.getActiveCall()) {
            offLineVideoTrack = (<div className='offline-videotrack'></div>);
        }

        return (
            <div id="uiTracksContainer">
                {spinner}
                {errorMessage}
                <div style={{ textAlign: 'center', display: (this.state.trackSizeDetected === true && this.state.renderingError == null ? 'block' : 'none') }}>
                    <TrackRender></TrackRender>
                    {offLineVideoTrack}
                </div>
            </div>);
    }

    getRenderingErrorCode = (error) => {
        let code = null;
        this.renderingErrorCodes.forEach(renderingCode => {
            if (error === renderingCode) {
                code = renderingCode;
            }
        });
        return code;
    }

    ipCamConnectingHandler = () => {
        this.setState({ trackSizeDetected: false, renderingError: null });
    }

    ipCamConnectedHandler = () => {
        this.setState({ trackSizeDetected: true });
    }

    setIpCamRenderingErrorHandler = (message, error) => {
        if (this.props.selectVideoInputIpCam === null)
            return;

        this.setRenderingErrorHandler(message, error);
    }

    setRenderingErrorHandler = (message, error) => {
        this.setState({ renderingError: message, renderingErrorCode: (error) ? this.getRenderingErrorCode(error) : null });
    }

    setIpCamStreamDimensionsHandler = (dimensions) => {
        this.ipCamStreamDimensions = dimensions;
        console.log('ip cam stream dimensions:', dimensions);
        if (!this.ipCamStreamPublished) {
            this.setupVideoIpCam(true);
            this.ipCamStreamPublished = true;
        }
    }

    getVideoTrackSizeCallback = (videoTrack) => {
        let streamDimensions = videoTrack.dimensions;
        if (this.props.selectVideoInputIpCam)
            streamDimensions = this.ipCamStreamDimensions;
        else if (!streamDimensions) {
            let currentVideoTrack = RaApiCallsCommunicationProvider.getCurrentLocalVideoTrack();
            if (currentVideoTrack && currentVideoTrack.track)
                streamDimensions = currentVideoTrack.track.dimensions;
        }

        return streamDimensions;
    }

    getVideoCropSizeCallback = () => {
        return { width: this.$$(window).width(), height: this.$$(window).height() };
    }

    isTrackSourceAvailableCallback = (trackSourceId) => {
        if ((trackSourceId === RENDERDEVICE_LOCALDEVICE && this.props.selectVideoInputIpCam !== null)
            || (trackSourceId === RENDERDEVICE_IPCAM && this.props.selectVideoInputIpCam === null)
            || (RaApiCalls.getActiveCall() && !this.props.online)
            || (!RaApiCalls.getActiveCall() && this.props.online))
            return false;

        return true;
    }

    cropLocalVideoPlaceHolder = (trackSourceId, videoTrack, renderElementSelector,
        isTrackSourceAvailableCallback, getVideoCropSizeCallback, getVideoTrackSizeCallback) => {

        console.log('cropLocalVideoPlaceHolder');

        if (!isTrackSourceAvailableCallback) {
            console.error('isTrackSourceAvailableCallback not defined');
            sessionStorage.removeItem('aspectRatio');
            return;
        }

        if (!getVideoCropSizeCallback) {
            console.error('getVideoCropSizeCallback not defined');
            return;
        }

        if (!getVideoTrackSizeCallback) {
            console.error('getVideoTrackSizeCallback not defined');
            return;
        }


        //se è stato cambiato il dispositivo di render prima della chamata a questo metodo non lo eseguo
        if (!isTrackSourceAvailableCallback(trackSourceId)) {
            if (RaApiCalls.getActiveCall() && !this.props.online) {

                if (!videoTrack.track) {
                    videoTrack.stop();
                }
            }
            return;
        }

        let self = this;
        setTimeout(function () {

            let cropSize = getVideoCropSizeCallback();

            let trackWidth = null;
            let trackHeight = null;

            let streamDimensions = getVideoTrackSizeCallback(videoTrack);

            if (streamDimensions) {
                trackWidth = streamDimensions.width;
                trackHeight = streamDimensions.height;
            }

            console.log('cropLocalVideoPlaceHolder trackWidth:' + trackWidth + ' trackHeight:' + trackHeight);

            if (trackWidth && trackHeight) {
                if (self.state.showVideoLowStreamingMessage === true)
                    self.setState({ showVideoLowStreamingMessage: false });

                let videoCropData = RaUI.getElementCropData(cropSize.width, cropSize.height, trackWidth, trackHeight);
                if (cropSize.width > 1025)
                    videoCropData.margin.left = 1025 * 0.15 * -1;

                if (sessionStorage.getItem('aspectRatio') !== videoCropData.aspectRatio) {
                    self.$$(renderElementSelector).attr("style", "width:" + videoCropData.width + "px;height:" + videoCropData.height + "px;margin-top:" + videoCropData.margin.top + "px;margin-left:" + videoCropData.margin.left + "px;overflow: hidden");
                    sessionStorage.setItem('aspectRatio', videoCropData.aspectRatio);
                    if (self.state.trackSizeDetected !== true) {
                        self.setState({ trackSizeDetected: true });
                        if (self.props.onTrackSizeDetected)
                            self.props.onTrackSizeDetected();
                    }
                }
            }
            else {
                if (self.state.showVideoLowStreamingMessage !== true)
                    self.setState({ showVideoLowStreamingMessage: true });
            }

            self.cropLocalVideoPlaceHolder(trackSourceId, videoTrack, renderElementSelector, isTrackSourceAvailableCallback, getVideoCropSizeCallback, getVideoTrackSizeCallback);

        }, 2000);
    }

    checkCameraPermissions = () => {

        if (RaCordova.isCordova()) {
            const self = this;
            RaCordovaPermissions.askCallPermissions()
                .then(function (response) {
                    console.log('request permissions success ' + JSON.stringify(response));
                })
                .catch(function (response) {
                    console.log('request permissions failed ' + JSON.stringify(response));

                    self.$f7.dialog.alert('You need to grant permissions in order to start the call', "Permessions", function () {
                        self.checkCameraPermissions();
                    });
                });
        }
    }

    componentDidMount = () => {
        this.checkCameraPermissions();
        this._localParticipantTracksNode = this.getLocalParticipantTracksNode();
        this.setupInputDevices({});
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        this._localParticipantTracksNode = this.getLocalParticipantTracksNode();
        this.setupInputDevices(prevProps);
    }

    setupInputDevices = (prevProps) => {

        let self = this;/*
    //GESTIONE TRACCIA AUDIO (E' SEMPRE QUELLA DEL DISPOSITIVO)
    if ((!prevProps.inputDevicesDetectionCompleated && self.props.inputDevicesDetectionCompleated))
        self.setupAudioInputDevice(true);
*/
        //GESTIONE TRACCIA VIDEO
        if (!self.props.selectVideoInputIpCam) {
            if (!self.props.inputDevicesDetectionCompleated) {
                RaMedia.selectDefaultDevices();
            }
            else {
                if (!self.props.selectedVideoInputDevice) {
                    RaMedia.selectDefaultDevices();
                }
                else {
                    let changeVideoInputDevice = prevProps.selectVideoInputIpCam || !prevProps.selectedVideoInputDevice || prevProps.selectedVideoInputDevice.deviceId !== self.props.selectedVideoInputDevice.deviceId;
                    //rilevati i device
                    if (!prevProps.inputDevicesDetectionCompleated
                        //avviata/finite una chiamata
                        || ((!prevProps.comunicationProviderData && self.props.comunicationProviderData) || (prevProps.comunicationProviderData && !self.props.comunicationProviderData))
                        //selezionato/modificato il  device locale
                        || changeVideoInputDevice) {
                        self.setupVideoInputDevice(true, changeVideoInputDevice);
                    }
                }
            }
        }
        /*
            else if(self.props.selectVideoInputIpCam && !prevProps.selectVideoInputIpCam) 
                self.setupVideoIpCam(true);*/  //NON CAMBIO LA TRACCIA UN QUESTO PUNTO, PERCHE' NON SI SANNO ANCORA LE DIMENSIONI DELLO STREAM DELL'IP, CHIAMO QUINDI QUESTO METODO NELL'HANDLER DELLE DIMENSIONI
    }

    setupAudioInputDevice = (shareVideoTrack) => {

        RaApiCallsCommunicationProvider.getTracksFromLocalAudioInputDevices([RaMedia.getSelectedAudioInputDevice().deviceId])
            .then(localTracks => {
                if (RaApiCalls.getActiveCall()) {
                    RaApiCallsCommunicationProvider.publishNewTracks(localTracks)
                        .catch(function (errorMessage) {
                            console.error(errorMessage);
                        });
                }
            });
    }

    setupVideoIpCam = (shareVideoTrack) => {

        let self = this;
        self.setState({ trackSizeDetected: false });
        let canvas = document.getElementById("ipcam-canvas");
        let mediaStream = canvas.captureStream();
        let tracks = mediaStream.getTracks().map(track => track.kind === 'audio' ? RaApiCallsCommunicationProvider.getLocalAudioTrack(track) : RaApiCallsCommunicationProvider.getLocalVideoTrack(track));

        console.log('ip cam track detected', tracks);
        if (shareVideoTrack && RaApiCallsCommunicationProvider.isVideoCallActive()) {
            RaApiCallsCommunicationProvider.publishNewTracks(tracks)
                .then(function () {
                    self.cropLocalVideoPlaceHolder(RENDERDEVICE_IPCAM, self.getVideoTrack(tracks), '#ipcam-image', self.isTrackSourceAvailableCallback, self.getVideoCropSizeCallback, self.getVideoTrackSizeCallback);
                }).catch(function (errorMessage) {
                    self.setRenderingErrorHandler(errorMessage);
                });
        }
        else {
            self.cropLocalVideoPlaceHolder(RENDERDEVICE_IPCAM, self.getVideoTrack(tracks), '#ipcam-image', self.isTrackSourceAvailableCallback, self.getVideoCropSizeCallback, self.getVideoTrackSizeCallback);
        }
    }

    setupVideoInputDevice = (shareVideoTrack, changeVideoInputDevice) => {
        this.setRenderingErrorHandler(null);
        this.ipCamStreamPublished = false;
        let self = this;
        self.setState({ trackSizeDetected: false });
        let currentVideoTrack = RaApiCallsCommunicationProvider.getCurrentLocalVideoTrack();
        //chiamata in corso e condivisione del mio streaming video
        if (currentVideoTrack) {

            if (shareVideoTrack && changeVideoInputDevice) {

                RaApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(RaMedia.getSelectedVideoInputDevice())
                    .then(videoTrack => {

                        if (shareVideoTrack) {
                            RaApiCallsCommunicationProvider.publishNewTracks([videoTrack])
                                .then(function () {
                                    self.cropVideoTrackStream(self, videoTrack);
                                })
                                .catch(function (errorMessage) {
                                    self.setRenderingErrorHandler(errorMessage);
                                });
                        }
                        else {
                            self.cropVideoTrackStream(self, videoTrack);
                        }
                    })
                    .catch(errorMessage => {
                        console.error(errorMessage);
                    });
            }
            else {
                self.cropVideoTrackStream(self, currentVideoTrack);
            }
        }
        else if (!this.props.online) {
            RaApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(RaMedia.getSelectedVideoInputDevice())
                .then(videoTrack => {
                    self.$$('.offline-videotrack').html('');
                    self.$$('.offline-videotrack').append(videoTrack.attach());
                    self.cropLocalVideoPlaceHolder(RENDERDEVICE_LOCALDEVICE, videoTrack, ".offline-videotrack > video", self.isTrackSourceAvailableCallback, self.getVideoCropSizeCallback, self.getVideoTrackSizeCallback);
                })
                .catch(errorMessage => {
                    console.error(errorMessage);
                });
        }
    }

    getVideoTrack = (tracks) => {

        let videoTrack = null;
        tracks.forEach(function (track) {

            if (track.kind === 'video') {
                videoTrack = track;
            }
        });
        return videoTrack;
    }

    cropVideoTrackStream = (self, track) => {

        self.cropLocalVideoPlaceHolder(RENDERDEVICE_LOCALDEVICE, track, "#" + RaUITracksNodesManager.getTrackNodeId(RaStorage.getProfile().UniqueId) + ' > video', self.isTrackSourceAvailableCallback, self.getVideoCropSizeCallback, self.getVideoTrackSizeCallback);
    }

}

// #region Redux
const mapStateToProps = state => {
    return {
        selectedVideoInputDevice: state.remoteAssistance.selectedVideoInputDevice,
        inputDevicesDetectionCompleated: state.remoteAssistance.inputDevicesDetectionCompleated,
        selectVideoInputIpCam: state.remoteAssistance.selectVideoInputIpCam,
        comunicationProviderData: state.remoteAssistance.comunicationProviderData
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalVideoStream);