
import appActionTypes from './actionTypes';

const initialState = {
    mustUpdateTargets: null,
    mustUpdateHome: null,
    orientation: null,
    activeCallContacts: []
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {

        case appActionTypes.SET_ORIENTATION:
            return {
                ...state,
                orientation: action.orientation,
            }
        case appActionTypes.SET_MUST_UPDATE_TARGETS:
            return {
                ...state,
                mustUpdateTargets: action.mustUpdateTargets,
            }
        case appActionTypes.SET_MUST_UPDATE_HOME:
            return {
                ...state,
                mustUpdateHome: action.mustUpdateHome,
            }
        case appActionTypes.SET_ACTIVE_CALL_CONTACTS:
            return {
                ...state,
                activeCallContacts: action.activeCallContacts,
            }

        default:
            console.log("Action sconosciuta: " + action.type);
    }
    return state;
};

export default appReducer;