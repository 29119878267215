import React from 'react';
import ReactDOM from 'react-dom';
import Framework7 from 'framework7/framework7.esm.bundle';
import Framework7React from 'framework7-react';
import App from './app.jsx';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import { RaReduxActions, RaCordova, RaUI } from 'pikkart-remote-assistance';
/*import * as actions from './ra-lib/redux/actions';
import RaCordova from './ra-lib/cordova.js';*/


Framework7.use(Framework7React);

// Mount React App
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app'),
);

// Trasforma .register in .unregister per disabilitare la PWA
if (!window.cordova || !window.cordova.version)
  serviceWorker.register();

var app = {
  // Application Constructor
  initialize: function () {
    if (window.cordova && window.cordova.version) {
      console.log("c'è cordova");
      store.dispatch(RaReduxActions.setCordovaReady(false));
    } else {
      console.log("non c'è cordova");
    }
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  },

  // deviceready Event Handler
  //
  // Bind any cordova events here. Common events are:
  // 'pause', 'resume', etc.
  onDeviceReady: function () {
    console.log('onDeviceReady');

    RaUI.lockOrientation();
    store.dispatch(RaReduxActions.setCordovaReady(true));
    RaCordova.init_cordova();
    //this.receivedEvent('deviceready');
  },

  // Update DOM on a Received Event
  // receivedEvent: function(id) {
  //     var parentElement = document.getElementById(id);
  //     var listeningElement = parentElement.querySelector('.listening');
  //     var receivedElement = parentElement.querySelector('.received');

  //     listeningElement.setAttribute('style', 'display:none;');
  //     receivedElement.setAttribute('style', 'display:block;');

  //     console.log('Received Event: ' + id);
  // }
};

app.initialize();