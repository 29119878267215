import React from 'react';
import { Block, Col, Preloader, Fab, Icon, Button, Link, Popover, Badge } from 'framework7-react';
import { connect } from 'react-redux';
import LocalVideoStream from '../commons/local-video-stream';
import RemoteVideoStream from '../commons/remote-video-stream';
import Spinner from '../../components/Spinner';
import { RaApiCallsPics, RaStorage, RaUIAnnotationCanvas, RaApiCalls, RaCordova } from 'pikkart-remote-assistance';
import Commons from '../../components/Commons';

class VideoCallMain extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      spinner: false,
      selectedImageFromClick: false,
      selectedImageUniqueId: null
    };
  }

  render() {

    let errorGui = null;
    if (this.state.errormessage !== null) {
      errorGui = (
        <Block style={{ marginTop: 0, paddingTop: '150px' }}>
          <p style={{ textAlign: 'center', color: 'white' }}>
            {this.state.errormessage}
          </p>
        </Block>
      );
    }


    if (this.props.takenSnapshots === null) {
      return (<Spinner />);
    }

    let annotationGUI;
    if (this.props.selectedSnapshot
      && this.props.callGUIMode === RaApiCalls.GUIMODE_ANNOTATIONS
      && this.props.activeCall != null) {
      annotationGUI = this.getSelectedImage();
    }

    let streamVideoUI = null;
    if (!RaApiCalls.getActiveCall() || Commons.shareUserVideoTrack()) {
      streamVideoUI = (<LocalVideoStream online={true} showSpinnerHandler={() => { return !this.props.callingState }}></LocalVideoStream>);
    }
    else if (RaApiCalls.getActiveCall() && !Commons.shareUserVideoTrack()) {
      streamVideoUI = (<RemoteVideoStream participantId={RaApiCalls.getActiveCall().CallerUniqueId}>
        -      </RemoteVideoStream>);
      /* if (this.props.activeCallContacts) {
         streamVideoUI = this.props.activeCallContacts.map((item, index) => {
 
           return item.Contact.UniqueId !== RaStorage.getProfile().UniqueId ? (<RemoteVideoStream participantId={item.Contact.UniqueId}>
           </RemoteVideoStream>) : null;
         });
       }*/
    }

    const imageCount = this.props.takenSnapshots.length;
    let picBadge;
    if (imageCount > 0) {
      picBadge = (<Badge color="red">{this.props.takenSnapshots.length}</Badge>);
    }

    let fab;
    console.log('getActiveCall ', RaApiCalls.getActiveCall());
    console.log('amICallController ', RaApiCalls.amICallController());
    if (RaApiCalls.getActiveCall() && RaApiCalls.amICallController()
      && (this.props.callingState === false)) {
      fab = (
        <div style={{ textAlign: "right", display: 'inline-flex' }}>
          <Fab onClick={(e) => { this.takeSnapshot() }} position="center-bottom" style={{ position: "fixed", bottom: '100px' }} slot="fixed">
            <Icon ios="f7:camera" aurora="f7:camera" md="material:camera"></Icon>
          </Fab>
          <Block style={{ position: 'absolute', zIndex: 100 }}>
            <Link className={'fabIconLink' + (this.props.callGUIMode === RaApiCalls.GUIMODE_ANNOTATIONS ? " fab-active" : '')}
              style={{ marginLeft: "10px", opacity: this.props.takenSnapshots.length && this.props.takenSnapshots.length > 0 ? '100%' : '30%' }}
              onClick={(e) => {
                if (this.props.takenSnapshots.length && this.props.takenSnapshots.length > 0) {
                  this.$f7.popover.open('.annotations-popover-menu');
                }
              }}
            >
              <Icon ios="f7:photo_on_rectangle" aurora="f7:photo_on_rectangle" md="material:photo_library">
                {picBadge}
              </Icon>
            </Link>
          </Block>
        </div>);
    }


    return (
      <div className={this.$f7.device.android && !RaCordova.isCordova() ? 'callTabAndroidChrome' : 'callTab'}>
        <div style={{ display: this.props.callGUIMode === RaApiCalls.GUIMODE_LIVECAMERA ? 'block' : 'none', overflow: 'hidden' }}>

          {errorGui}
          {fab}
          {streamVideoUI}

          <Popover className="annotations-popover-menu" style={{ width: '100%' }}>
            {this.getPictures()}
          </Popover>

        </div>
        {annotationGUI}
      </div>
    );
  }

  pageModeSnapshotClicked = () => {
    RaApiCalls.switchToAnnotationsGUI();
  }


  getPictures = () => {

    if (!this.props.takenSnapshots || this.props.takenSnapshots === null || this.props.takenSnapshots.length === 0) {
      return (null);
    }

    const images = this.props.takenSnapshots.map((item, index) => {

      return (
        <div key={"swiperslide" + index} style={{ textAlign: 'initial', marginRight: '15px' }}>
          <span class="picIndex"># {index + 1}</span><br></br>
          <img src={item.ImageUrl} alt="placeholder" onClick={(e) => {
            if (RaApiCalls.amICallController()) {
              let self = this;
              this.setState({ selectedImageFromClick: true }, () => {
                self.showImage(item);
              });
            }
          }
          } />

          {(RaApiCalls.amICallController()) && (
            <span class="badge" onClick={(e) => {
              this.deletePic(item.UniqueId);
            }}>X</span>
          )}
        </div>
      );
    });

    return (
      <div class="gallery-tabbar">
        <div class='picsGallery' style={{ position: 'fixed' }}>
          {images}
        </div>
      </div>
    );
  }

  setRenderingErrorHandler = (message, error) => {
    this.setState({ errormessage: message });
  }

  getSelectedImage = () => {
    if (this.props.selectedSnapshot) {
      if (this.state.spinner === true) {
        return (
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <Preloader size={42}></Preloader>
            </Col>
          </Block>
        );
      }
      else {

        let closeButton;
        //if (this.isUserCaller())
        if (RaApiCalls.amICallController())
          closeButton = (<Button color="gray" onClick={() => {
            RaApiCalls.switchToLiveCameraGUI();
          }}>CLOSE</Button>);

        console.log('selected image uniqueid showing image ' + this.props.selectedSnapshot.UniqueId);
        console.log('this.props.selectedSnapshot.UniqueId === this.state.selectedImageUniqueId ' + this.props.selectedSnapshot.UniqueId === this.state.selectedImageUniqueId);
        if (this.props.selectedSnapshot.UniqueId === this.state.selectedImageUniqueId) {
          return (<div>
            <RaUIAnnotationCanvas
              live={true}
              cancelAnnotationButton={

                <Fab position="right-top" slot="fixed" color='gray' style={{ marginTop: 0 }}>
                  <Icon ios="f7:arrow_uturn_left" aurora="f7:arrow_uturn_left" md="material:undo"></Icon>
                </Fab>}

              backgroundImage={this.props.selectedSnapshot}
              imageUniqueId={this.props.selectedSnapshot.UniqueId}
              callId={this.props.activeCall.Call.UniqueId}
              dataTrack={this.props.dataTrack}
              myProfile={this.props.profile}
              $$={this.$$} >
            </RaUIAnnotationCanvas>
            {closeButton}
          </div>);
        }
      }
    }

    return null;
  }

  showImage = (img) => {
    const self = this;
    console.log('selected image uniqueid ' + img.UniqueId);
    // this.$$('#annotations-popover-menu').opened = false;

    //this.setState({ spinner: true });

    self.setState({ spinner: false, selectedImageUniqueId: img.UniqueId }, () => {

      self.$f7.popover.close('.annotations-popover-menu');

      if (RaApiCalls.amICallController()) {
        RaApiCallsPics.selectPic(img);
        RaApiCalls.switchToAnnotationsGUI();
      }
    });
  }

  isUserCaller = () => {
    return RaApiCalls.getActiveCall() && RaApiCalls.getActiveCall().CallerUniqueId === RaStorage.getProfile().UniqueId;
  }

  componentDidMount = () => {

    //RaApiCalls.askCallerVideoState();
    /*RaApiCalls.connectToCall(Commons.shareUserVideoTrack(), true)
      .catch(error => {
        if (this.props.connectToCallError) {
          this.props.connectToCallError(error);
        }
      });*/

    /*if (!this.props.live && this.props.takenSnapshots === null
      && this.props.callId) {
      this.fetchPicturesList();
    }
    else */if (this.props.live && this.props.takenSnapshots && this.props.takenSnapshots.length > 0) {
      if (this.props.onPicSelectedHandler)
        this.props.onPicSelectedHandler(this.props.selectedSnapshot);
    }

    if (this.props.live) {
      RaApiCallsPics.sendSelectedPicRequestMessage();
    }

    if (RaApiCalls.getActiveCall().UniqueId && this.props.token !== null) {
      this.fetchPics();
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {

    /*if (!this.props.live
      && (prevProps === null || this.props.callId !== prevProps.callId))
      this.fetchPicturesList();
    else {*/
    if (prevProps === null || this.props.takenSnapshots !== prevProps.takenSnapshots)
      this.setState({ errormessage: null });
    //}

    if (prevProps.selectedSnapshot && this.props.selectedSnapshot) {
      if (prevProps.selectedSnapshot.UniqueId !== this.props.selectedSnapshot.UniqueId) {
        if (!this.state.selectedImageFromClick)
          this.showImage(this.props.selectedSnapshot);
        else {
          this.setState({ selectedImageFromClick: false });
        }
      }
    }
    else if (!prevProps.selectedSnapshot && this.props.selectedSnapshot) {
      if (!this.state.selectedImageFromClick)
        this.showImage(this.props.selectedSnapshot);
      else {
        this.setState({ selectedImageFromClick: false });
      }
    }
  }

  fetchPics = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = () => {
      /*this.props.takenSnapshots.map((item, index) => {
        return item;
      });*/
      this.setState({
        errormessage: null,
      });
    }

    RaApiCallsPics.getActiveCallPics()
      .then(function (response) {
        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });

  };

  /*fetchPicturesList = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (images) => {
      this.setState({ errormessage: null });
    }

    RaApiCallsPics.getCallPics(this.props.callId)
      .then(function (response) {
        //handle success
        console.log(response);

        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        //handle error
        errorFn(response.message);
      });

  };*/

  deletePic = (picId) => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          spinner: false,
          errormessage: errorMsg,
        });
      }
    }

    const successFn = () => {

      this.setState({ spinner: false, errormessage: null });

    }

    this.$f7.dialog.confirm('Do you want to delete this pic?', 'Pics', () => {
      this.setState({ spinner: true });
      RaApiCallsPics.deletePic(picId)
        .then(function (response) {
          if (response.result.success === true) {
            successFn();
          } else {
            errorFn(response.result.message);
          }
        })
        .catch(function (response) {
          //handle error
          errorFn(response.message);
        });
    })
  }

  takeSnapshot() {
    //const videoDiv = document.getElementById('videoinputpreview');

    const tracksContainer = document.getElementById('tracksContainer');
    let videoDiv = null;

    if (tracksContainer) {
      var divs = tracksContainer.getElementsByTagName('video');
      var videoTagArray = [];
      for (var i = 0; i < divs.length; i += 1) {
        videoTagArray.push(divs[i]);
      }

      if (videoTagArray.length > 0)
        videoDiv = videoTagArray[0];
      /*divArray.forEach(div => {
        if (div.querySelector('video')) {
          videoDiv = div;
        }
      });*/

      if (videoDiv) {
        const canvasDiv = this.createCanvas(tracksContainer);

        if (tracksContainer != null) {
          this.setCanvasSizeToVideo(canvasDiv, videoDiv);
          setTimeout(() => {
            this.takeLocalVideoSnapshot(videoDiv, canvasDiv);
          }, 200);
        }
        else {
          this.$f7.dialog.alert('video unavailable', 'Video');
        }
      }
      else {
        this.$f7.dialog.alert('video unavailable', 'Video');
      }
    }
    else {
      this.$f7.dialog.alert('video unavailable', 'Video');
    }
  }

  createCanvas = (div) => {
    var canvas = document.createElement('canvas');
    canvas.id = "videoCanvas";
    //div.appendChild(canvas);

    return canvas;
  }

  takeLocalVideoSnapshot = (video, canvas) => {
    this.showPreloader(true);

    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const jpegUrl = canvas.toDataURL("image/jpeg");

    const base64Url = jpegUrl.substring(jpegUrl.lastIndexOf(',') + 1)

    const errorFn = (errorMsg) => {

      this.showPreloader(false);
      this.showToastBottom(errorMsg);
    }

    const successFn = (resultData) => {
      this.setState({ errormessage: null });
      this.showPreloader(false);
    }

    //const bodyFormData = new FormData();
    //bodyFormData.set('picbase64', base64Url);
    RaApiCallsPics.saveNewPic(base64Url)
      .then(function (response) {
        //handle success
        console.log(response);

        if (response.result.success === true) {
          // handle success
          //response.data.data contiene il token
          successFn(response);
        } else {
          // handle error
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        //handle error
        errorFn(response.message);
      });
  }

  setCanvasSizeToVideo = (canvas, video) => {
    canvas.height = video ? video.clientHeight : 0;
    canvas.width = video ? video.clientWidth : 0;
  }

  showToastBottom = (text) => {
    this.toastBottom = this.$f7.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    this.toastBottom.open();
  };

  showPreloader = (show) => {
    if (show) {
      this.$f7.preloader.show();
    }
    else {
      this.$f7.preloader.hide();
    }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    takenSnapshots: state.remoteAssistance.takenSnapshots,
    selectedSnapshot: state.remoteAssistance.selectedSnapshot,
    dataTrack: state.remoteAssistance.dataTrack,
    profile: state.remoteAssistance.profile,
    callGUIMode: state.remoteAssistance.callGUIMode,
    activeCall: state.remoteAssistance.activeCall,
    callingState: state.remoteAssistance.callingState
    // activeCallContacts: state.app.activeCallContacts
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(VideoCallMain);
