
import { RaApiAuth, RaStorage, RaCordova, CONTACT_ROLE } from 'pikkart-remote-assistance';

export class Commons {

  static MAINPAGEINDEX_HOME = 0;
  static MAINPAGEINDEX_RECENTS = 1;
  static MAINPAGEINDEX_DOCUMENTS = 2;
  static MAINPAGEINDEX_IPCAMSETTINGS = 3;
  static MAINPAGEINDEX_DOCUMENTS = 4;
  static MAINPAGEINDEX_PROFILE = 5;

  static showErrorMessage = (self, text) => {
    //if (!this.toastBottom) {
    let toastBottom = self.$f7.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    //}
    toastBottom.open();
  };

  static showAlert = (self, text, title) => {
    self.$f7.dialog.alert(text, title);
  }

  static checkAuthentication = (router) => {
    
    if (!RaApiAuth.isAuthenticated() && router.currentRoute.url !== "/login/") {
      router.navigate('/', {reloadAll: true});
    }
  };

  static hasIpCam = () => {
    //return true;
    let profile = RaStorage.getProfile();
    return (RaCordova.isCordova() && profile && profile.IpCamsEnabled && profile.IpCamsEnabled.length > 0);
  }

  static homeWithCamera = () => {
    let profile = RaStorage.getProfile();
    return profile && profile.Role && profile.Role.UxStartsWithCamera;
  }

  static shareUserVideoTrack = () => {
    let profile = RaStorage.getProfile();
    return profile && profile.Role && profile.Role.Code === CONTACT_ROLE.ASSISTED;
  }

  static callContactErrorHandler = (self, targetContact, errorMsg, errorCode) => {

    let message = null;

    if (targetContact.Company
        && targetContact.Company.UnavailableMessage) { 
      message = targetContact.Company.UnavailableMessage;
    }

    if (!message) {
      if (errorCode === 404) {
        message = targetContact.Name + ' is offline at the moment.';
      }
      else {
        message = errorMsg;
      }
    }
    
    if (errorCode === 409) {
      message = targetContact.Name + ' is occupied at the moment.';
    } 

    if (message && message.length > 100) {
      message = message.substring(0, 100) + '...';
    }

    self.$f7.dialog.alert(message, 'Unable to call');

  }

}


export default Commons;